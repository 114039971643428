import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { activeBogoLists } from '../../../../utils';

const ProductInfo = memo(function ProductInfo({
  singleProductData,
  varientList,
  singleVariantData,
  variantDataState,
  oldProductPrice,
  outOfStockCondition,
}) {
  const bogoList = useSelector((state) => state.bogoList);

  const [bogoDescription, setBogoDescription] = useState('');

  // setting bogo description
  useEffect(() => {
    if (bogoList.bogoList.length <= 0) return;

    // filtering only valid bogo lists
    const validBogoList = activeBogoLists(bogoList.bogoList);
    // console.log('validBogoList: ', validBogoList);

    const settingBogoDescription = (id) => {
      const productIsInBogoDeal = validBogoList.find((deal) => {
        return deal.items.includes(id);
      });

      setBogoDescription(
        productIsInBogoDeal ? productIsInBogoDeal.description : '',
      );
    };

    // for Non Variant products
    if (singleProductData && singleProductData.isvarient === '0') {
      const id = singleProductData.id;
      settingBogoDescription(id);
      return;
    }

    // for variant products
    if (
      singleProductData &&
      singleProductData.isvarient === '1' &&
      singleVariantData
    ) {
      settingBogoDescription(singleVariantData.id);
      return;
    }

    setBogoDescription('');
  }, [singleProductData, singleVariantData, bogoList]);

  return (
    <div className="quickvee-single-product-main-detail">
      <h1>{singleProductData?.title}</h1>
      <p style={{ height: '36px' }}>
        <span>
          {varientList?.length > 0
            ? singleVariantData
              ? `$${singleVariantData?.price}`
              : variantDataState.loading && `$${oldProductPrice}`
            : null}
          {(varientList?.length <= 0 || !varientList) &&
            singleProductData &&
            `$${singleProductData?.price}`}
        </span>{' '}
        <del>
          {singleVariantData && parseFloat(singleVariantData?.compare_price) > 0
            ? `$${singleVariantData?.compare_price}`
            : singleProductData &&
              !singleVariantData &&
              parseFloat(singleProductData.compare_price) > 0
            ? `$${singleProductData?.compare_price}`
            : ''}
          {/* {singleVariantData?.compare_price > singleVariantData?.price
            ? `$${singleVariantData?.compare_price}`
            : singleProductData?.compare_price > 0
            ? `$${singleProductData?.compare_price}`
            : ''}{' '} */}
        </del>
      </p>

      {bogoDescription && !outOfStockCondition ? (
        <p className="bogo-discount" style={{ color: '#00A86B' }}>
          {bogoDescription}
        </p>
      ) : null}
    </div>
  );
});

export default ProductInfo;
