import {
  Alert,
  Box,
  Collapse,
  IconButton,
  FormControl,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import QButton from '../Main/QButton';
import ChangePasswordFormLogic from './ChangePasswordFormLogic';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';

const ChangePasswordForm = () => {
  const inputRefs = useRef({});

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetKey = searchParams.get('vc');
  const Navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [reshow, setReShow] = useState(false);

  const handleTouchStart = () => {
    setShow(true);
  };

  const handleTouchEnd = () => {
    setShow(false);
  };

  const handleTouchStartRe = () => {
    setReShow(true);
  };

  const handleTouchEndRe = () => {
    setReShow(false);
  };

  const {
    CustomerChangePasswordInput,
    values,
    handleSubmitChangePassword,
    submitmessage,
    setsubmitmessage,
    openAlert,
    setOpenAlert,
    alertStatus,
    setAlertStatus,
  } = ChangePasswordFormLogic();
  const closeAlert = () => {
    setOpenAlert(false);
    // setsubmitmessage("")
    Navigate('/customer-login');
  };
  if (submitmessage) {
    setTimeout(() => {
      setOpenAlert(false);
      // setsubmitmessage("")ddd
      Navigate('/customer-login');
    }, 8000);
  }

  if (values.password !== values.confirmpassword) {
    values.errors.confirmpassword = 'Confirm Password not matching';
  }

  useEffect(() => {
    if (!resetKey) {
      Navigate('/customer-login');
    }
  }, [resetKey]);

  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  return (
    <>
      <form className="login-customer-form">
        <FormControl className="login-customer-form" autoComplete="off">
          <h1>Change Password</h1>
          <span className="sub-heading-from">
            We'll send you a link to reset your password.
          </span>

          <FormControl
            fullWidth
            style={{ marginBottom: '24px' }}
            className="input-outer-div"
          >
            <TextField
              className="input-field"
              label="Password"
              variant="outlined"
              // size="small"
              autoComplete="off"
              type={show === true ? 'text' : 'password'}
              onChange={CustomerChangePasswordInput}
              name="password"
              value={values.password}
            ></TextField>
            <span className="input-error">{values.errors.password}</span>
            <span
              className="show-hide-button"
              onMouseDown={() => setShow(true)}
              onMouseUp={() => setShow(false)}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              {show === true && values.password.length > 0 ? 'Hide' : 'Show'}
            </span>
          </FormControl>

          <FormControl fullWidth className="input-outer-div">
            <TextField
              className="input-field"
              label="Confirm Password"
              variant="outlined"
              // size="small"
              autoComplete="off"
              type={reshow === true ? 'text' : 'password'}
              onChange={CustomerChangePasswordInput}
              name="confirmpassword"
              value={values.confirmpassword}
            ></TextField>
            <span className="input-error">{values.errors.confirmpassword}</span>
            <span
              className="show-hide-button"
              onMouseDown={() => setReShow(true)}
              onMouseUp={() => setReShow(false)}
              onTouchStart={handleTouchStartRe}
              onTouchEnd={handleTouchEndRe}
            >
              {reshow === true && values.confirmpassword.length > 0
                ? 'Hide'
                : 'Show'}
            </span>
          </FormControl>

          <QButton
            onClickHandle={handleSubmitChangePassword}
            name={'Change Password'}
          />
        </FormControl>

        <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
        >
          {alertStatus === true ? (
            <Collapse in={openAlert}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="success"
                    size="small"
                    onClick={closeAlert}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          ) : (
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="error"
                    size="small"
                    onClick={closeAlert}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          )}
        </Box>
      </form>
    </>
  );
};

export default ChangePasswordForm;
