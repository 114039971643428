import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_ALL_BOGO_LIST } from '../../../Constants/Config';

const initialState = {
  loading: false,
  bogoList: [],
  error: '',
};

export const fetchBogoLists = createAsyncThunk(
  'bogoList/fetchBogoLists',
  async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_ALL_BOGO_LIST,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      if (
        response.status === 200 &&
        response.data &&
        response.data.bogo_list &&
        response.data.bogo_list.length > 0
      ) {
        return response.data.bogo_list;
      } else {
        return [];
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  },
);

const bogoListSlice = createSlice({
  name: 'bogoList',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchBogoLists.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBogoLists.fulfilled, (state, action) => {
      state.loading = false;
      state.bogoList = action.payload || [];
      state.error = '';
    });
    builder.addCase(fetchBogoLists.rejected, (state, action) => {
      state.loading = false;
      state.bogoList = [];
      state.error = action.error.message;
    });
  },
});

export default bogoListSlice.reducer;
