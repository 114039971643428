import React, { useEffect, useMemo, useState } from 'react';

// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  FormControl,
  MenuItem,
  Select,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { InputLabel } from '@mui/material';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';
import {
  removeCartItem,
  setCartData,
  updateCartItem,
  updateCartWithVariants,
} from '../../../redux/features/Cart/cartSlice';
import MobileViewPaymentWishList from './MobileViewPaymentWishList';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Validation from '../../../Constants/Validation';
import {
  GET_VARIANT_PRODUCT_QUANTITY,
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_BASE_URL,
} from '../../../Constants/Config';
import AlertModal from '../../ReusableComponent/AlertModal';
import { fetchVariantData } from '../../../redux/features/SingleStore/variantSlice';
import AlreadyInCartModal from './AlreadyInCartModal';
import RemoveCartItemModal from './RemoveCartItemModal';

const PaymentWishLIst = ({
  cartItem,
  merchantId,
  cartData,
  setUpdatingCart,
}) => {
  const isMobile = useMediaQuery('(max-width:480px)');
  const {
    updateProductFromCart,
    handleImagesArray,
    filterCartData,
    handleDeleteCartProduct: removeCartProduct,
    handleUpdateCartItem,
  } = Validation();

  const dispatch = useDispatch();

  const cartDataState = useSelector((state) => state.cart.cartData);
  // const VarientDataState = useSelector((state) => state.variantCart);
  const variantDataState = useSelector((state) => state.variant);
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );

  const isDisabled = useMemo(() => {
    // console.log('variantDataState: ', variantDataState);
    const productIsDisabled =
      variantDataState.variantCartData.data?.find(
        (prod) => prod.id === cartItem.variantId,
      )?.disable === '1' ?? false;

    const productIsOutOfStock =
      +cartItem.product.quantity >= 0 &&
      cartItem.product.isstockcontinue === '0' &&
      cartItem.product.trackqnty === '1' &&
      cartItem.selectedQuantity >= +cartItem.product.quantity &&
      cartItem.product.isvarient !== '1';

    return productIsDisabled || productIsOutOfStock;
  }, [cartItem, variantDataState]);

  const [disableQuantityUpdate, setdisableQuantityUpdate] =
    useState(isDisabled);
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);

  // fetching cart products
  const getCartProducts = () => {
    if (
      cartDataState &&
      variantDataState?.variantCartData?.data &&
      cartProductDetails
    ) {
      const data = filterCartData(
        cartDataState,
        variantDataState?.variantCartData?.data,
        cartProductDetails,
      );

      return data ? data : [];
    }
  };

  // fetching products complete info from variant cart state
  const getCartProductInfo = (cartProducts) => {
    const variantCartProductsInCart = [];
    variantDataState.variantCartData.data?.forEach((prod) => {
      cartProducts.forEach((cartProduct) => {
        if (
          prod.id === cartProduct.variantId &&
          prod.product_id === cartProduct.productId
        ) {
          variantCartProductsInCart.push(prod);
        }
      });
    });

    return variantCartProductsInCart && variantCartProductsInCart.length > 0
      ? variantCartProductsInCart
      : [];
  };

  // fetching current product
  const getCurrentProduct = (variantCartProductsInCart) => {
    const currentProduct = variantCartProductsInCart?.find(
      (prod) =>
        prod.id === cartItem?.variantId &&
        prod.product_id === cartItem?.productId,
    );
    return currentProduct;
  };

  // handling variant product qty in the cart drawer,
  // checking if qty is available to add and its add button is disabled
  useEffect(() => {
    if (
      (disableQuantityUpdate || cartItem.isOutOfStock) &&
      cartItem.product.isvarient === '1'
    ) {
      // getting cart products
      const cartProducts = getCartProducts();

      // storing all the products that are in cart, with complete info for each product
      const variantCartProductsInCart = getCartProductInfo(cartProducts);

      // from the Cart products getting current product info using the cartItem prop.
      const currentProduct = getCurrentProduct(variantCartProductsInCart);

      const productId = currentProduct?.product_id;
      const productPurchaseQTY = +currentProduct?.purchase_qty;

      // getting same PQ products that are in Cart with complete info for each product..
      const samePQProducts = variantCartProductsInCart.filter(
        (prod) =>
          prod?.product_id === productId &&
          +prod?.purchase_qty === productPurchaseQTY,
      );

      // for getting the selectedQuantity from the cart products
      const samePQProductsInCart = [];
      samePQProducts?.forEach((prod) => {
        cartProducts?.forEach((cartProduct) => {
          if (
            cartProduct?.productId === prod?.product_id &&
            cartProduct?.variantId === prod?.id
          ) {
            samePQProductsInCart.push(cartProduct);
          }
        });
      });

      const totalQty = samePQProductsInCart?.reduce(
        (acc, curr) => (acc += curr.selectedQuantity),
        0,
      );

      // conditions for allowing to add more qty-
      // 1) all cart products qty (totalQty) should be less than PQ limit
      // 2) disableQuantityUpdate should be true
      // 3) current cart item quantity should be less than actual qty available (eg: a product has 5 qty and in cart its 2)
      if (
        totalQty < productPurchaseQTY &&
        disableQuantityUpdate &&
        cartItem.selectedQuantity < parseFloat(currentProduct.quantity)
      ) {
        setdisableQuantityUpdate(false);
      }
    }
  }, [
    cartItem,
    cartDataState,
    disableQuantityUpdate,
    variantDataState?.variantCartData,
    cartProductDetails,
  ]);

  const validatePQProduct = (newVariantDetail) => {
    // console.log('newVariantDetail: ', newVariantDetail);
    if (!newVariantDetail.purchase_qty)
      return { isOutOfStock: false, pqLimitReached: false };

    // getting cart products & removing current cart item
    const cartProducts = getCartProducts().filter(
      (prod) => prod.cartProductId !== cartItem.cartProductId,
    );

    // storing all the products that are in cart, with complete info for each product
    const variantData = getCartProductInfo(cartProducts);
    const variantCartProductsInCart = [...variantData, newVariantDetail];

    const productId = newVariantDetail?.product_id;
    const productPurchaseQTY = +newVariantDetail?.purchase_qty ?? 0;

    // getting same PQ products that are in Cart with complete info for each product..
    const samePQProducts = variantCartProductsInCart.filter(
      (prod) =>
        prod?.product_id === productId &&
        +prod?.purchase_qty === productPurchaseQTY,
    );

    // for getting the selectedQuantity from the cart products
    const samePQProductsInCart = [];
    samePQProducts?.forEach((prod) => {
      cartProducts?.forEach((cartProduct) => {
        if (
          cartProduct?.productId === prod?.product_id &&
          cartProduct?.variantId === prod?.id
        ) {
          samePQProductsInCart.push(cartProduct);
        }
      });
    });

    const totalQty = samePQProductsInCart?.reduce(
      (acc, curr) => (acc += curr.selectedQuantity),
      0,
    );

    const isOutOfStock =
      totalQty >= productPurchaseQTY ||
      newVariantDetail?.disable === '1' ||
      (parseFloat(newVariantDetail.quantity) <= 0 &&
        newVariantDetail.isstockcontinue === '0' &&
        newVariantDetail.trackqnty === '1');

    return { isOutOfStock, pqLimitReached: totalQty >= productPurchaseQTY };
  };

  // const handleDeleteCartProduct = (product) => {
  //   const { cartProductId, categoryId, productId, timeStamp, addedOn } =
  //     product;
  //   const cartItemData = {
  //     cartProductId,
  //     categoryId,
  //     productId,
  //     timeStamp,
  //     addedOn,
  //   };
  //   const cartKey = `cart_${merchantId}`;

  //   if (product.variantId) {
  //     // current selected product with complete live data
  //     const selectedProduct = VarientDataState?.variantCartData?.find(
  //       ({ id, product_id }) =>
  //         product.variantId === id && productId === product_id,
  //     );

  //     // products inside cart with same product id and pq qty
  //     const productsWithSamePQ =
  //       VarientDataState?.variantCartData &&
  //       VarientDataState?.variantCartData.length > 0
  //         ? VarientDataState?.variantCartData?.filter(
  //             ({ product_id, purchase_qty }) =>
  //               product_id === selectedProduct.product_id &&
  //               selectedProduct.purchase_qty === purchase_qty,
  //           )
  //         : [];

  //     const sameProductsVariantIds = productsWithSamePQ.map((item) => item.id);

  //     // same other PQ products except current product
  //     const sameProductsFromCart = cartData.filter(
  //       (item) =>
  //         sameProductsVariantIds.includes(item.variantId) &&
  //         item.addedOn !== product.addedOn,
  //     );

  //     if (sameProductsFromCart && sameProductsFromCart.length > 0) {
  //       sameProductsFromCart?.forEach(({ productId }) => {
  //         const bool =
  //           productId === cartItem?.product.id && disableQuantityUpdate;

  //         if (bool) {
  //           setdisableQuantityUpdate(false);
  //         }
  //       });
  //     }
  //   }

  //   dispatch(removeCartItem(cartItemData)); // for redux slice
  //   removeProductFromCart(cartItemData, cartKey);
  // };

  useEffect(() => {
    if (
      cartItem?.selectedQuantity >= 1 &&
      parseInt(cartItem?.product?.quantity) === cartItem?.selectedQuantity &&
      cartItem?.product?.isstockcontinue === '0' &&
      cartItem?.product?.trackqnty === '1' &&
      cartItem?.product?.isvarient !== '1'
    ) {
      setdisableQuantityUpdate(true);
    } else {
      // if (cartItem.isOutOfStock) return;

      // getting cart products
      const cartProducts = getCartProducts();

      // storing all the products that are in cart, with complete info for each product
      const variantCartProductsInCart = getCartProductInfo(cartProducts);

      // from the Cart products getting current product info using the cartItem prop.
      const currentProduct = getCurrentProduct(variantCartProductsInCart);

      if (currentProduct?.disable === '1') {
        setdisableQuantityUpdate(true);
        return;
      }

      const productQty = +cartItem.product?.quantity;
      const productPQQty = +cartItem.product?.purchase_qty;
      let maxQty = 0;

      if (productQty && productPQQty) {
        maxQty = productQty > productPQQty ? productPQQty : productQty;
      } else if (productQty && !productPQQty) {
        maxQty = productQty;
      }

      cartItem &&
      cartItem.product &&
      cartItem.product.quantity !== '0' &&
      cartItem.product.isstockcontinue === '0' &&
      cartItem.product.trackqnty === '1' &&
      cartItem.product.isvarient !== '1' &&
      cartItem.selectedQuantity >= maxQty
        ? setdisableQuantityUpdate(true)
        : setdisableQuantityUpdate(false);
    }
  }, [cartItem]);

  // Only for variant product, disabling product if max qty reached in the cart drawer
  // const allowUpdatingProduct = (
  //   variantId,
  //   productId,
  //   type,
  //   qty,
  //   currentProduct,
  // ) => {
  //   // selected product... jisko update karna hai.. complete data hai isme
  //   const selectedProduct = VarientDataState?.variantCartData?.find(
  //     ({ id, product_id }) => variantId === id && productId === product_id,
  //   );

  //   // productId & purchase qty match karke dusre products find kiye hai from cart & complete data hai isme each product ke
  //   const productsWithSamePQ = VarientDataState?.variantCartData?.filter(
  //     ({ product_id, purchase_qty }) =>
  //       product_id === selectedProduct.product_id &&
  //       selectedProduct.purchase_qty === purchase_qty,
  //   );

  //   // just getting product info from cartData...
  //   let dataset = [];
  //   productsWithSamePQ?.forEach((prod) => {
  //     const temp = cartData.filter(
  //       ({ productId, variantId }) =>
  //         prod.product_id === productId && prod.id === variantId,
  //     );

  //     dataset.push(...temp);
  //   });

  //   // only storing the products with same PQ but not selected product
  //   const otherProducts =
  //     dataset &&
  //     dataset.length > 0 &&
  //     dataset?.filter(
  //       (product) =>
  //         product?.productId === selectedProduct?.product_id &&
  //         product?.variantId !== selectedProduct?.id,
  //     );

  //   // current products total qty from cart
  //   const currentProducts = cartData.filter(
  //     (item) => item.cartProductId === currentProduct.cartProductId,
  //   );

  //   const currentProductTotalQty = currentProducts.reduce(
  //     (acc, curr) => (acc += curr.selectedQuantity),
  //     0,
  //   );

  //   // other same PQ products total qty from cart
  //   // other products total qty + current products total qty + 1 (1 is for increment)
  //   const totalQty =
  //     otherProducts?.reduce((acc, curr) => {
  //       return (acc += parseFloat(curr.selectedQuantity));
  //     }, 0) +
  //     parseFloat(currentProductTotalQty) +
  //     (type === 'increment' ? 1 : 0);

  //   // getting products max qty limit.
  //   const maxQty = (input) => (type === 'increment' ? input - 1 : input);
  //   const productPurchaseQTY = parseFloat(selectedProduct.purchase_qty);

  //   // for not allowing user to add 1 more qty of the product...
  //   if (maxQty(totalQty) === productPurchaseQTY) {
  //     setdisableQuantityUpdate(true);
  //     return false;
  //   }

  //   // if 1 more qty of the product is available for purchase then allowing...
  //   return true;
  // };

  // const handleUpdateCartItem = async (product, qty, type) => {
  //   const { cartProductId, categoryId, productId, variantId, addedOn } =
  //     product;

  //   if (product && product.varients && product.varients.length >= 1) {
  //     // 1. check total qty in cart
  //     // 2. check if total qty is same as PQ qty then disable this button.

  //     const bool = allowUpdatingProduct(
  //       variantId,
  //       productId,
  //       type,
  //       qty,
  //       product,
  //     );
  //     if (!bool) return;

  //     // if product passes Purchase quantity conditions then checking its available qty from backend.
  //     const data = {
  //       merchant_id: product.product.merchant_id,
  //       product_id: productId,
  //       variant_id: variantId,
  //       quantity: qty,
  //     };
  //     try {
  //       const response = await axios.post(
  //         BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
  //         data,
  //         { headers: { 'Content-Type': 'multipart/form-data' } },
  //       );

  //       if (
  //         response.data.status === 200 &&
  //         parseInt(response.data.result.quantity) >= qty
  //       ) {
  //         const cartItem = {
  //           cartProductId: cartProductId,
  //           categoryId: categoryId,
  //           productId: productId,
  //           quantity: qty,
  //           addedOn,
  //           type,
  //           product,
  //         };
  //         const cartKey = `cart_${merchantId}`;

  //         dispatch(updateCartItem(cartItem));
  //         updateProductFromCart(cartItem, cartKey, qty, type, cartProductId);
  //         setdisableQuantityUpdate(false);
  //       } else if (response.data.status === 400) {
  //         setdisableQuantityUpdate(true);
  //       }
  //     } catch (error) {
  //       return new Error('Something went wrong');
  //     }
  //   } else {
  //     const data = {
  //       merchant_id: merchantId,
  //       product_id: productId,
  //       variant_id: '',
  //       quantity: qty,
  //     };

  //     try {
  //       const response = await axios.post(
  //         BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
  //         data,
  //         { headers: { 'Content-Type': 'multipart/form-data' } },
  //       );

  //       if (
  //         response.data.status === 200 &&
  //         parseInt(response.data.result.quantity) >= qty
  //       ) {
  //         setdisableQuantityUpdate(false);

  //         const cartItemadd = {
  //           cartProductId,
  //           categoryId,
  //           productId,
  //           quantity: qty,
  //           addedOn,
  //           type,
  //           product,
  //         };

  //         const cartKey = `cart_${merchantId}`;
  //         dispatch(updateCartItem(cartItemadd));
  //         updateProductFromCart(cartItemadd, cartKey, qty, type, cartProductId);
  //       } else if (response.data.status === 400) {
  //         setdisableQuantityUpdate(true);
  //       }
  //     } catch (error) {
  //       return new Error('Something went wrong');
  //     }
  //   }
  // };

  const getDefaultSelectedOption = (optionsName, options) => {
    const defaultVarients = cartItem.varients;
    const matchingItem = defaultVarients.find(
      (item) =>
        optionsName === item.varientName && options.includes(item.varientVl),
    );
    return matchingItem ? matchingItem.varientVl : null;
  };

  const handleOtherProduct = (product, newProduct) => {
    const otherCartProducts = getCartProducts().filter(
      (prod) => prod.cartProductId !== cartItem.cartProductId,
    );

    // storing all the products that are in cart, with complete info for each product
    const variantData = getCartProductInfo(otherCartProducts);
    const variantCartProductsInCart = newProduct
      ? [...variantData, newProduct]
      : variantData;

    const productId = newProduct?.product_id;
    const productPurchaseQTY = +newProduct?.purchase_qty ?? 0;

    // getting same PQ products that are in Cart with complete info for each product..
    const samePQProducts = variantCartProductsInCart.filter(
      (prod) =>
        prod?.product_id === productId &&
        +prod?.purchase_qty === productPurchaseQTY,
    );

    // for getting the selectedQuantity from the cart products
    const samePQProductsInCart = [];
    samePQProducts?.forEach((prod) => {
      otherCartProducts?.forEach((cartProduct) => {
        if (
          cartProduct?.productId === prod?.product_id &&
          cartProduct?.variantId === prod?.id
        ) {
          samePQProductsInCart.push(cartProduct);
        }
      });
    });

    const totalQty =
      samePQProductsInCart?.reduce(
        (acc, curr) => (acc += curr.selectedQuantity),
        0,
      ) + 1;

    const productIsOutOfStock =
      newProduct.disable !== '1' ||
      (parseFloat(newProduct.quantity) <= 0 &&
        newProduct.isstockcontinue === '0' &&
        newProduct.trackqnty === '1');

    // conditions for allowing to add more qty-
    // 1) all cart products qty (totalQty) should be less than PQ limit
    // 2) disableQuantityUpdate should be true
    // 3) current cart item quantity should be less than actual qty available (eg: a product has 5 qty and in cart its 2)
    if (
      totalQty < productPurchaseQTY &&
      (product.isOutOfStock || product.pqLimitReached) &&
      product.quantity < parseFloat(newProduct.quantity) &&
      !productIsOutOfStock
    ) {
      return { ...product, isOutOfStock: false, pqLimitReached: false };
    } else {
      return product;
    }
  };

  const handleVariant = async (e, varient, cartItem) => {
    // fetching new variants data
    const data = {
      merchant_id: merchantId,
      product_id: cartItem.productId,
      variant: Object.values(
        cartItem.varients.map((v) =>
          v.varientName === varient ? e.target.value : v.varientVl,
        ),
      ).join('/'),
    };

    // console.log('cart item: ', cartItem);

    let newVariantDetail;

    if (data && data.variant) {
      newVariantDetail = await dispatch(fetchVariantData(data));
    }

    const cartProducts = getCartProducts();
    const variantData = getCartProductInfo(cartProducts);

    const productAlreadyExistsInCart = variantData.find(
      (prod) =>
        prod?.id === newVariantDetail?.payload.id &&
        prod?.product_id === newVariantDetail.payload?.product_id,
    );

    if (productAlreadyExistsInCart) {
      setIsAlreadyInCart(() => true);
      return;
    }

    setUpdatingCart(() => true);

    // checking PQ qty limit and isOutOfStock condition
    const { isOutOfStock, pqLimitReached } = validatePQProduct(
      newVariantDetail.payload,
    );

    const productIsOutOfStock =
      newVariantDetail.payload?.disable === '1' ||
      (parseFloat(newVariantDetail.payload.quantity) <= 0 &&
        newVariantDetail.payload.isstockcontinue === '0' &&
        newVariantDetail.payload.trackqnty === '1');

    // getting cart data from localstorage
    const cartDataInLocalStorage = JSON.parse(
      localStorage.getItem(`cart_${merchantId}`),
    );

    if (productIsOutOfStock || isOutOfStock || pqLimitReached) {
      setdisableQuantityUpdate(() => true);
    }

    // console.log('cartDataInLocalStorage: ', cartDataInLocalStorage);
    // updating cart data with new variants
    const updatedCart = cartDataInLocalStorage.map((product) => {
      // return product.cartProductId === cartItem.cartProductId
      return product.addedOn === cartItem.addedOn
        ? {
            ...product,
            varients: product?.varients?.map((variant) =>
              variant.varientName === varient
                ? { ...variant, varientVl: e.target.value }
                : variant,
            ),
            isOutOfStock: isOutOfStock
              ? isOutOfStock
              : productIsOutOfStock
              ? productIsOutOfStock
              : false,
            pqLimitReached: pqLimitReached ?? false,
            quantity: 1,
            msg: '',
          }
        : handleOtherProduct(product, newVariantDetail.payload);
    });

    // console.log('variant change updatedCart: ', updatedCart);
    // updating the store cart with new variants
    dispatch(updateCartWithVariants(updatedCart));

    // updating local storage cart details with new variants
    localStorage.setItem(`cart_${merchantId}`, JSON.stringify(updatedCart));
  };

  const imagesArray =
    cartItem &&
    cartItem.product &&
    cartItem.product.media &&
    handleImagesArray(cartItem.product.media);

  const deleteCartProduct = (cartItem) => {
    // handleDeleteCartProduct ye function hai
    removeCartProduct(
      merchantId,
      variantDataState.variantCartData.data,
      cartData,
      cartItem,
      disableQuantityUpdate,
      setdisableQuantityUpdate,
      removeCartItem,
    );
  };

  return (
    <>
      {isMobile ? (
        <Grid container>
          <Grid item xs={12}>
            <MobileViewPaymentWishList
              BASE_URL_SANDBOX={BASE_URL_SANDBOX}
              ProductDefaultImage={ProductDefaultImage}
              cartItem={cartItem}
              merchantId={merchantId}
              imagesArray={imagesArray}
              handleDeleteCartProduct={deleteCartProduct}
              handleUpdateCartItem={handleUpdateCartItem}
              disableQuantityUpdate={disableQuantityUpdate}
              getDefaultSelectedOption={getDefaultSelectedOption}
              handleVariant={handleVariant}
              setdisableQuantityUpdate={setdisableQuantityUpdate}
              variantData={variantDataState.variantCartData.data}
              cartData={cartData}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className={`payment-page-wishlist-cart-items ${
            cartItem?.isOutOfStock ? 'error' : ''
          }`}
        >
          <Grid item className="payment-page-wishlist-item-image">
            <div>
              <img
                onError={(e) => {
                  e.target.src = ProductDefaultImage;
                }}
                src={
                  cartItem && cartItem.product && cartItem.product.media
                    ? `${PRODUCT_IMAGES_BASE_URL}upload/products/${merchantId}/${imagesArray[0]}`
                    : ProductDefaultImage
                }
                width={55}
                height="auto"
                alt="product"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={10}
            style={{ display: 'flex', flexDirection: 'column' }}
            className="payment-page-wishlist-item-content"
          >
            <div className="top-content">
              <p>
                {cartItem &&
                  cartItem.product &&
                  cartItem.product.title &&
                  cartItem.product.title}
              </p>
              <AlertModal
                MoveWishlist={false}
                RemoveAllCart={false}
                removeSingleFromCart={true}
                handleDeleteCartProduct={() => {
                  deleteCartProduct(cartItem);
                  // handleDeleteCartProduct(cartItem);
                }}
              />
            </div>
            <Grid container className="middle-content">
              <Grid item xs={6} className="increment-decremnt-button">
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    md={7}
                    lg={7}
                    xl={6}
                    xxl={5}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <RemoveCartItemModal
                      handleDeleteCartProduct={deleteCartProduct}
                      cartItem={cartItem}
                    />

                    {/* <button
                      onClick={() => {
                        deleteCartProduct(cartItem);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <defs>
                          <clipPath id="clipPath">
                            <rect
                              id="Rectangle_3261"
                              data-name="Rectangle 3261"
                              width="16"
                              height="16"
                              transform="translate(-590 2518)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="plus"
                          transform="translate(590 -2518)"
                          clipPath="url(#clipPath)"
                        >
                          <line
                            id="Line_687"
                            data-name="Line 687"
                            y2="11.25"
                            transform="translate(-576.375 2526) rotate(90)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                        </g>
                      </svg>
                    </button> */}
                    <span>{cartItem && cartItem.selectedQuantity}</span>
                    <button
                      disabled={disableQuantityUpdate}
                      onClick={() => {
                        // handleUpdateCartItem(
                        //   cartItem,
                        //   cartItem.selectedQuantity + 1,
                        //   'increment',
                        // )

                        handleUpdateCartItem(
                          cartItem,
                          cartItem.selectedQuantity + 1,
                          'increment',
                          setdisableQuantityUpdate,
                          merchantId,
                          variantDataState.variantCartData.data,
                          cartData,
                        );
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <defs>
                          <clipPath id="clipPath">
                            <rect
                              id="Rectangle_3261"
                              data-name="Rectangle 3261"
                              width="16"
                              height="16"
                              transform="translate(-590 2518)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="plus"
                          transform="translate(590 -2518)"
                          clipPath="url(#clipPath)"
                        >
                          <line
                            id="Line_686"
                            data-name="Line 686"
                            y2="11.25"
                            transform="translate(-582 2520.375)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                          <line
                            id="Line_687"
                            data-name="Line 687"
                            y2="11.25"
                            transform="translate(-576.375 2526) rotate(90)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                        </g>
                      </svg>
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                className="amount-calculation d-flex justify-content-end"
              >
                <span>
                  {cartItem &&
                  !isNaN(cartItem.price) &&
                  parseFloat(cartItem.price) >= 0
                    ? `$ ${parseFloat(cartItem.price).toFixed(2)}`
                    : ''}
                </span>
              </Grid>
            </Grid>
            <Grid container className="bottom-dropdowns-for-categories mt-2">
              {cartItem &&
                cartItem.allVarients &&
                cartItem.allVarients.map(
                  (varient, index) =>
                    varient[`optionsvl${index + 1}`].length >= 1 && (
                      <Grid item xs={6} sm={6} md={4} key={index}>
                        <FormControl
                          fullWidth
                          // variant="filled"
                          // className="modal-form-single-item select-varient-cart"
                          className="form-select-formcontrol "
                        >
                          <InputLabel
                            id={`demo-simple-select-filled-label-${index}`}
                          >
                            <span style={{ textTransform: 'capitalize' }}>
                              {varient[`options${index + 1}`]}
                            </span>
                          </InputLabel>
                          <Select
                            labelId={`demo-simple-select-filled-label-${index}`}
                            className="input-field select-OnFocus"
                            id={`demo-simple-select-filled-${index}`}
                            variant="outlined"
                            label={varient[`options${index + 1}`]}
                            name="varient"
                            onChange={(e) =>
                              handleVariant(
                                e,
                                varient[`options${index + 1}`],
                                cartItem,
                              )
                            }
                            value={getDefaultSelectedOption(
                              varient[`options${index + 1}`],
                              varient[`optionsvl${index + 1}`],
                            )}
                          >
                            {varient[`optionsvl${index + 1}`].map(
                              (option, optionIndex) => (
                                <MenuItem key={optionIndex} value={option}>
                                  {option}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    ),
                )}
            </Grid>

            {cartItem.bogoDiscountApplied ? (
              <p className="no-stock-variant-product">
                BOGO Deal Discount Applied!
              </p>
            ) : null}

            {Boolean(cartItem?.msg.trim()) ? (
              <p className="no-stock-variant-product">{cartItem?.msg}</p>
            ) : cartItem?.isOutOfStock && cartItem?.pqLimitReached ? (
              <p className="no-stock-variant-product">
                Purchase Quantity Limit Reached!
              </p>
            ) : cartItem?.isOutOfStock ? (
              <p className="no-stock-variant-product">Out of Stock!</p>
            ) : null}
          </Grid>
        </Grid>
      )}
      <AlreadyInCartModal
        isAlreadyInCart={isAlreadyInCart}
        setIsAlreadyInCart={setIsAlreadyInCart}
      />
    </>
  );
};

export default PaymentWishLIst;
