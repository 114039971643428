import './Styles/App.css';
import './Styles/Product.css';
import './Styles/media.css';
import './Styles/custom.css';
import Main from './Main';
import LogoutTimer from '../src/Components/ReusableComponent/LogoutTimer';
import './Styles/customSingleStore.css';
import './Styles/customPaymentCart.css';
import { ToastContainer } from 'react-toastify';
import NewMain from './NewMain';
function App() {
  return (
    <>
      <LogoutTimer />
      {/* <Main /> */}
      <NewMain />
      <ToastContainer />
    </>
  );
}

export default App;
