import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from './redux/features/Authentication/Customer/authUserSlice';
import { fetchAllStates } from '../src/redux/features/Home/allStatesSlice';
import Cookies from 'js-cookie';

const MainAuthentication = lazy(() =>
  import('./Components/Authentication/Main/MainAuthentication'),
);
const MainOrder = lazy(() => import('./Components/Order/Main/MainOrder'));
const MainCart = lazy(() => import('./Components/Payment/Main/MainCart'));
const MainSingleStore = lazy(() =>
  import('./Components/Store/SingleStore/MainSingleStore'),
);
// const OrderSummeryPage = lazy(() =>
//   import('./Components/orderSummeryPage/orderSummery'),
// );
const NewOrderSummary = lazy(() =>
  import('./Components/orderSummeryPage/NewOrderSummary'),
);
const PageNotFound = lazy(() =>
  import('./Components/ReusableComponent/PageNotFound'),
);
const MainHome = lazy(() => import('./Components/MainHome/Main'));

const Loading = () => <></>;

const NewMain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const StateList = useSelector((state) => state?.allStatesData?.allStateList);

  const localUserData = Cookies.get('userLoginData');
  const isGuest = Cookies.get('is_guest');

  useEffect(() => {
    if (StateList.length === 0) {
      dispatch(fetchAllStates());
    }

    if (localUserData) {
      dispatch(setAuthUser(JSON.parse(localUserData)));
      dispatch(setAuthentication(true));
      dispatch(setGuestLogin(isGuest));
    }
  }, [localUserData, isGuest, dispatch, StateList.length]);

  useEffect(() => {
    if (!location.pathname.startsWith('/payment-cart')) {
      sessionStorage.removeItem('orderStatus');
    }
  }, [location]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route exact path="/" element={<MainHome visible={'home'} />} />
        <Route
          exact
          path="/category/:productCode"
          element={<MainHome visible={'category'} />}
        />
        <Route
          exact
          path="/forgot-password"
          element={<MainAuthentication visible={'forgot-password'} />}
        />
        <Route
          exact
          path="/customer-login"
          element={<MainAuthentication visible={'login'} />}
        />
        <Route
          exact
          path="/about-us"
          element={<MainHome visible={'AboutUs'} />}
        />
        <Route
          exact
          path="/privacy-policy"
          element={<MainHome visible={'policy'} />}
        />
        <Route
          exact
          path="/terms-condition"
          element={<MainHome visible={'termconditions'} />}
        />
        <Route
          exact
          path="/merchantlogin"
          element={<MainAuthentication visible={'merchantlogin'} />}
        />
        <Route
          exact
          path="/changePassword"
          element={<MainAuthentication visible={'changePassword'} />}
        />
        <Route
          exact
          path="/register"
          element={<MainAuthentication visible={'register'} />}
        />
        <Route
          exact
          path="/guest-checkout"
          element={<MainAuthentication visible={'guest-checkout'} />}
        />
        <Route exact path="/order" element={<MainOrder />} />
        <Route
          exact
          path="/order/order-history"
          element={<MainOrder visible={'orderhistory'} />}
        />
        <Route
          exact
          path="/order/profile"
          element={<MainOrder visible={'profile'} />}
        />
        <Route
          exact
          path="/order/storecreditzone"
          element={<MainOrder visible={'StoreCreditZone'} />}
        />
        <Route
          exact
          path="/order/loyaltypointzone"
          element={<MainOrder visible={'LoyaltyPointZone'} />}
        />
        <Route exact path="/payment-cart/:productCode" element={<MainCart />} />
        <Route exact path="/store" element={<MainSingleStore />} />
        <Route
          exact
          path="/wishlist/:productCode"
          element={<MainSingleStore visible={'wishlist'} />}
        />
        <Route
          exact
          path="/merchant/:productCode"
          element={<MainSingleStore visible={'merchant'} />}
        />
        <Route
          exact
          path="/product/:productCode"
          element={<MainSingleStore visible={'product'} />}
        />
        <Route
          exact
          path="/orderSummeryPage"
          element={<NewOrderSummary visible={'orderSummery'} />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default NewMain;
