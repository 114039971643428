// import React from 'react';
// import SingleStoreWishlist from '../Wishlist/SingleStoreWishlist';
// import SingleStoreAllInformationPage from './SingleStoreAllInformationPage';
// import SingleProductPage from '../Products/SingleProductPage';
// // import { useDispatch } from 'react-redux';
// // import { fetchSingleStoreData } from '../../../redux/features/SingleStore/singleStoreSlice';

// const SingleStore = ({ visible, toggleDrawer }) => {
//   return (
//     <>
//       <div className="quickvee-single-store-main-area-detail-page">
//         {visible === 'wishlist' && <SingleStoreWishlist visible={visible} />}
//         {visible === 'product' && (
//           <SingleProductPage visible={visible} toggleDrawer={toggleDrawer} />
//         )}
//         {visible === 'merchant' && (
//           <SingleStoreAllInformationPage visible={visible} />
//         )}
//         {/* {visible === 'StoreCreditZone' && <StoreCreditZone visible={visible} />}
//         {visible === 'LoyaltyPointZone' && <LoyaltiPointZone visible={visible} />} */}
//       </div>
//     </>
//   );
// };

// export default SingleStore;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SingleStoreWishlist from '../Wishlist/SingleStoreWishlist';
import SingleStoreAllInformationPage from './SingleStoreAllInformationPage';
import { Skeleton, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import SingleProductPage from '../Products/SingleProductPage';
import { PRODUCT_IMAGES_BASE_URL } from '../../../Constants/Config';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';
import LocationIcon from '../../../Assets/Store/location.svg';
import { useLocation } from 'react-router-dom';
import ViewFullHours from './ViewFullHours';
// import { useDispatch } from 'react-redux';
// import { fetchSingleStoreData } from '../../../redux/features/SingleStore/singleStoreSlice';
import SearchBar from './SearchBar';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useMediaQuery } from '@mui/material';

// import './../../../Styles/SingleStore.css'
import Footer from '../../MainHome/Footer';
const SingleStore = ({
  visible,
  toggleDrawer,
  merchantId,
  orderType,
  isOvelay,
  handleOverlayClickClose,
  handleNavClick,
}) => {
  const [categories, setCategories] = useState();
  const location = useLocation();
  const currentUrl = location.pathname;

  const [singleStoreData, setSingleStoreData] = useState(null);
  const [isHideStore, setHideStore] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const SingleStoresDataState = useSelector((state) => state.singleStores);
  useEffect(() => {
    if (
      !SingleStoresDataState.loading &&
      SingleStoresDataState?.storesData?.store_data &&
      SingleStoresDataState.storesData.categories
    ) {
      setSingleStoreData(SingleStoresDataState.storesData.store_data);
      setCategories(SingleStoresDataState.storesData.categories);
    }
  }, [SingleStoresDataState, SingleStoresDataState.loading]);

  useEffect(() => {
    if (currentUrl.split('/')[1] === 'product') {
      setHideStore(true);
    } else if (currentUrl.split('/')[1] === 'wishlist') {
      setHideStore(true);
    } else {
      setHideStore(false);
    }
  }, [currentUrl]);

  return (
    <>
      {isOvelay ? (
        <div
          onClick={() => {
            handleOverlayClickClose();
            handleNavClick();
          }}
          className="overlay"
        ></div>
      ) : (
        ''
      )}
      <Grid
        container
        justifyContent={'start'}
        style={{}}
        className="quickvee-single-store-main-area-detail-page"
      >
        <Grid style={{ minHeight: 'calc(100vh - 137px)' }} item md={12} xl={12}>
          {isHideStore ? (
            ''
          ) : (
            <div>
              {isMobile ? (
                <div className="songleStoreSearchBar">
                  <SearchBar merchantId={merchantId} orderType={orderType} />
                </div>
              ) : (
                ''
              )}

              {!SingleStoresDataState.loading && SingleStoresDataState.error ? (
                <h1> Error : {SingleStoresDataState.error} </h1>
              ) : null}
              <div className={`quickvee-single-store-logo-singleStore `}>
                {SingleStoresDataState.loading ? (
                  <Skeleton
                    variant="circular"
                    width={75}
                    height={75}
                    style={{ borderRadius: '50%' }}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                      to={`/merchant/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
                    >
                      <img
                        src={
                          singleStoreData?.ses_m_img
                            ? // ? `${BASE_URL_SANDBOX}upload/${singleStoreData?.ses_m_img}`
                              `${PRODUCT_IMAGES_BASE_URL}upload/${singleStoreData?.ses_m_img}`
                            : ProductDefaultImage
                        }
                        alt="store"
                        loading="lazy"
                        onError={(e) => {
                          e.target.src = ProductDefaultImage;
                        }}
                      />
                    </Link>

                    <div
                      className={`quickvee-left-menu-single-store-detail-sticky `}
                    >
                      {singleStoreData ? (
                        <div
                          className={
                            'quickvee-single-store-content-singleStore'
                          }
                        >
                          <span>
                            {singleStoreData?.store_name
                              ? singleStoreData.store_name
                              : ''}

                            {SingleStoresDataState.loading ? (
                              ''
                            ) : singleStoreData?.store_oc_status === 'open' ? (
                              <span
                                className="store-open-close-status"
                                style={{ background: '#14AE2D' }}
                              >
                                Open
                              </span>
                            ) : (
                              <span
                                className="store-open-close-status"
                                style={{ background: '#E55252' }}
                              >
                                Closed
                              </span>
                            )}
                          </span>
                          <p className={` `}>
                            <img
                              src={LocationIcon}
                              alt="location"
                              loading="lazy"
                            />
                            {singleStoreData && singleStoreData.add
                              ? singleStoreData.add + ', '
                              : ''}

                            <>
                              {singleStoreData?.city
                                ? singleStoreData.city + ', '
                                : ''}
                              {singleStoreData?.state
                                ? singleStoreData.state + ', '
                                : ''}
                              {singleStoreData?.zip
                                ? singleStoreData.zip + '. '
                                : ''}
                            </>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                      <ViewFullHours
                        SingleStoresDataState={SingleStoresDataState}
                        orderMethod={orderType}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {visible === 'wishlist' && <SingleStoreWishlist visible={visible} />}
          {visible === 'product' && (
            <SingleProductPage visible={visible} toggleDrawer={toggleDrawer} />
          )}
          {visible === 'merchant' && (
            <SingleStoreAllInformationPage visible={visible} />
          )}
        </Grid>
        <div className="common-footer-div profile-footer">
          <Footer />
        </div>
      </Grid>
    </>
  );
};

export default SingleStore;
