import React, { useContext, useEffect, useState } from 'react';
import { fetchSearchCategoryData } from '../../redux/features/SearchCategory/searchCategorySlice';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.webp';
import SingleProduct from '../Store/Products/SingleProduct';
import { PRODUCT_IMAGES_BASE_URL } from '../../Constants/Config';
import { ContextSearch } from '../../Context/SearchContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const SingleCategory = () => {
  const { checkOrderMethod } = useContext(ContextSearch);
  const [noDataFound, setNoDataFound] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { productCode: category } = useParams();
  const [searchCategoryData, setSearchCategoryData] = useState();
  const searchCategoryDataState = useSelector((state) => state.searchCategory);

  useEffect(() => {
    // if user is coming from home no need to call api because it will have data
    if (
      location?.state?.from === 'home' &&
      location?.state?.data?.smartsearch === category
    )
      return;

    const data = {
      smartsearch: category,
    };
    if (data && data.smartsearch) {
      dispatch(fetchSearchCategoryData(data));
    }
  }, [location, category]);

  const dataformater = (incoming) => {
    try {
      incoming = JSON.parse(JSON.stringify(incoming));
      let stores = incoming.stores || [];
      let store_products = incoming.store_products || [];
      let myMap = new Map();
      for (let index = 0; index < store_products.length; index++) {
        const element = store_products[index];
        if (element && Array.isArray(element) && element.length) {
          myMap.set(element[0].merchant_id, element);
        }
      }
      let formatedData = stores.map((singleStore) => {
        if (myMap.has(singleStore.merchant_id)) {
          singleStore['products'] = myMap.get(singleStore.merchant_id);
        } else singleStore['products'] = [];
        return singleStore;
      });
      return formatedData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    // if User is coming from Home page & Same Category is mentioned in Web URL also data is available
    if (
      location?.state?.from === 'home' &&
      location?.state?.data?.smartsearch === category
    ) {
      let value = dataformater(location?.state.data);
      setSearchCategoryData(value);
      setNoDataFound(false);
      return;
    }

    const hasStores = !!(
      searchCategoryDataState?.searchCategoryData?.stores?.length > 0
    );
    const hasProducts = !!(
      searchCategoryDataState?.searchCategoryData?.store_products?.length > 0
    );

    // if User has manually opened category page, fetching data from redux slice
    if (!searchCategoryDataState.loading && hasProducts && hasStores) {
      let value = dataformater(searchCategoryDataState.searchCategoryData);
      setSearchCategoryData(value);
      setNoDataFound(false);
    } else {
      setNoDataFound(true);
    }
  }, [searchCategoryDataState, location, category]);

  return (
    <>
      {/* Error Section */}
      {noDataFound && !searchCategoryDataState.loading && (
        <div className="alert home_alert_div" role="alert">
          <h3>Sorry! We couldn't find any matches</h3>
          <p>Please check the spelling or try searching something else.</p>
        </div>
      )}

      {searchCategoryDataState.loading ? <p>Loading... </p> : null}

      {searchCategoryData &&
        searchCategoryData.length >= 1 &&
        searchCategoryData.map((item, index) =>
          item.products.length >= 1 ? (
            <div key={index} className="single-store-sproductListing-section">
              <section>
                <div className="search-category-page-store-detail">
                  <div className="search-category-store-image-div">
                    <img
                      src={
                        item.img
                          ? // ? `${BASE_URL_SANDBOX}upload/${item.img}`
                            `${PRODUCT_IMAGES_BASE_URL}upload/${item.img}`
                          : ProductDefaultImage
                      }
                      alt={item.name}
                    />
                  </div>
                  <div className="search-category-page-store-description">
                    <span>{item.name}</span>
                    <p>
                      {item.a_address_line_1 +
                        ' ' +
                        item.a_address_line_2 +
                        ' ' +
                        item.a_city +
                        ' ' +
                        item.a_state +
                        ',' +
                        item.a_zip}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="scroller-of-product"></div>
                  <Link
                    to={`/merchant/${
                      item.merchant_id
                    }?orderMethod=${checkOrderMethod(item)}`}
                    className="search-category-visit-store-btn d-flex flex-nowrap"
                  >
                    Visit Store
                  </Link>
                </div>
              </section>
              <div>
                <Swiper
                  // slidesPerView={2}
                  centeredSlides={false}
                  spaceBetween={20}
                  grabCursor={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                    534: {
                      slidesPerView: 2.3,
                      spaceBetween: 20,
                    },
                    600: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    930: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                    1500: {
                      slidesPerView: 6,
                      spaceBetween: 30,
                    },
                    1700: {
                      slidesPerView: 7,
                      spaceBetween: 30,
                    },
                  }}
                  modules={[Pagination]}
                  className="media-scroller-upper  mySwiper-singleProductListing"
                >
                  {item.products.map((singleProduct, key) => (
                    <SwiperSlide
                      key={singleProduct.id}
                      className="catagories-swiper-SingleProductListing"
                    >
                      <SingleProduct
                        product={singleProduct}
                        categoryId={singleProduct.cotegory}
                        Wishlist={false}
                        merchantId={singleProduct.merchant_id}
                        item={item}
                        hideVariantAndWishlist={true}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            ''
          ),
        )}
    </>
  );
};

export default SingleCategory;
