import React, { useState, useEffect, useRef } from 'react';
import DeleteIcon from '../../../Assets/Payment/delete.svg';
import AddIcon from '../../../Assets/Payment/add.svg';
import { Button, Grid } from '@mui/material';
import PaymentWishLIst from './PaymentWishLIst';
import CrossIcon from '../../../Assets/Store/cross.svg';
import ProductImage from '../../../Assets/Store/Product.png';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import EmptyCart from '../../../Assets/Store/emptyCart.svg';
import { useSelector, useDispatch } from 'react-redux';
import Validation from '../../../Constants/Validation';
import { Skeleton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addToWishlist } from '../../../redux/features/Wishlist/wishlistSlice';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import AlertModal from '../../ReusableComponent/AlertModal';
import CouponTipSection from './CouponTipSection';
import CouponTipSectionLogic from './CouponTipSectionLogic';
import { useMediaQuery } from '@mui/material';
import Loaderfile from '../Loader/loaderfile';
import { fetchVariantCartData } from '../../../redux/features/Cart/variantCartSlice';
import OutOfStockProductsModal from './OutOfStockProductsModal';
import { handleBogoDeals } from '../../../utils';

const Cart = ({ setVisible, visible, merchantId, activeHours }) => {
  const Navigate = useNavigate();
  const { filterCartData, filterVarientDataFromCart } = Validation();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [cartData, setCartAllData] = useState([]);
  const cartDataState = useSelector((state) => state.cart.cartData);
  const storeProductDataState = useSelector((state) => state.storeProductData);
  // const VarientDataState = useSelector((state) => state.variantCart);
  const variantDataState = useSelector((state) => state.variant);
  const orderMethod = searchParams.get('orderMethod');
  const authUserData = useSelector((state) => state.authUser.UserData);
  const { isAuthenticated } = useSelector((state) => state.authUser);
  const { isGuest } = useSelector((state) => state.authUser);
  const cartDivRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const isMobile = useMediaQuery('(max-width:480px)');
  const storeDataFutureOrderState = useSelector(
    (state) => state?.singleStores?.storesData?.store_data?.future_ordering,
  );
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );
  const bogoList = useSelector((state) => state.bogoList);
  const [updatingCart, setUpdatingCart] = useState(false);
  const [productsNotValid, setProductsNotValid] = useState(false);

  const moveallproductToWishlist = () => {
    if (authUserData?.id) {
      // Create a Set to store unique productIds
      const uniqueProductIdsSet = new Set();

      // Loop through the array and filter unique objects
      cartDataState.forEach((obj) => {
        if (!uniqueProductIdsSet.has(obj.productId)) {
          uniqueProductIdsSet.add(obj.productId);

          const data = {
            user_id: authUserData?.id,
            merchants_id: merchantId,
            product_id: obj.productId,
            category_id: obj.categoryId,
          };

          dispatch(addToWishlist(data));
        }
      });
    } else {
      Navigate('/customer-login');
    }
  };

  const deletallProductFromCart = () => {
    dispatch(setCartData([]));
    setCartAllData([]);
    const cartKey = `cart_${merchantId}`;
    localStorage.removeItem(cartKey);

    // const bogoCartKey = `bogo_cart_${merchantId}`;
    // localStorage.removeItem(bogoCartKey);
  };

  // setting the payment-cart products details [filterCartData]
  useEffect(() => {
    // console.log('cartDataState: ', cartDataState);
    if (
      cartDataState &&
      variantDataState?.variantCartData?.data &&
      cartProductDetails
    ) {
      const products = filterCartData(
        cartDataState,
        variantDataState?.variantCartData?.data,
        cartProductDetails,
      );
      // console.log('products: ', products);

      const cartKey = `cart_${merchantId}`;
      const cartData = JSON.parse(localStorage.getItem(cartKey)) || [];
      // console.log('cartData: ', cartData);

      const newArray = [];
      // adding added on key
      products.forEach((item, index) => {
        newArray.push({
          ...item,
          addedOn:
            cartData[index] && cartData[index].addedOn
              ? cartData[index].addedOn
              : cartData[cartData.length - 1].addedOn + 1,
        });
      });
      // console.log('newArray: ', newArray);

      const afterBogoCart = handleBogoDeals(newArray, bogoList.bogoList);
      const isBogoApplied = afterBogoCart.some(
        (item) => item.bogoDiscountApplied,
      );
      // console.log('afterBogoCart: ', afterBogoCart);
      // console.log('isBogoApplied: ', isBogoApplied);

      if (bogoList.bogoList.length > 0 && isBogoApplied) {
        setCartAllData(afterBogoCart);
      } else {
        setCartAllData(newArray);
      }

      // setCartAllData(newArray);

      // console.log('Cart items: ', products);
      // setCartAllData(products);

      if (products.length === cartDataState.length) {
        setUpdatingCart(() => false);
      }
    }
  }, [
    bogoList,
    merchantId,
    cartProductDetails,
    cartDataState,
    variantDataState?.variantCartData,
  ]);

  useEffect(() => {
    const divElement = cartDivRef.current;

    if (divElement) {
      // Check if the div has overflow
      const hasOverflowNow = divElement.scrollHeight > divElement.clientHeight;
      setHasOverflow(hasOverflowNow);
    }
  }, [cartData]);

  useEffect(() => {
    const data = localStorage.getItem('outOfStockProducts');

    if (visible === 'myPayment' && data && JSON.parse(data).length > 0) {
      setProductsNotValid(() => true);
    }
  }, [visible]);

  return (
    <>
      <OutOfStockProductsModal
        cartData={cartData}
        productsNotValid={productsNotValid}
        setProductsNotValid={setProductsNotValid}
      />
      {cartData.length >= 1 && !updatingCart ? (
        <div className="payment-stepper-cart">
          <div className="payment-stepper-wishlist-item">
            {isMobile ? (
              ''
            ) : (
              <div className="payment-stepper-wishlist-item-topper">
                <Link
                  to={`/merchant/${merchantId}?orderMethod=${orderMethod}`}
                  className="payment-all-cart-add-more-products"
                >
                  Add More Products
                  <img
                    src={AddIcon}
                    alt="delete"
                    style={{ margin: '0px 0px 0px 10px' }}
                  />
                </Link>

                {isGuest !== 'yes' ? (
                  <div className="payment-cart-remove-from-cart-wishlist-modal">
                    <AlertModal
                      MoveWishlist={true}
                      RemoveAllCart={false}
                      moveallproductToWishlist={moveallproductToWishlist}
                      deletallProductFromCart={deletallProductFromCart}
                      User={authUserData?.id}
                      merchantId={merchantId}
                    />
                    <AlertModal
                      MoveWishlist={false}
                      RemoveAllCart={true}
                      moveallproductToWishlist={moveallproductToWishlist}
                      deletallProductFromCart={deletallProductFromCart}
                    />
                  </div>
                ) : (
                  <div className="payment-cart-remove-from-cart-wishlist-modal">
                    <AlertModal
                      MoveWishlist={false}
                      RemoveAllCart={true}
                      moveallproductToWishlist={moveallproductToWishlist}
                      deletallProductFromCart={deletallProductFromCart}
                    />
                  </div>
                )}
              </div>
            )}
            <Grid
              container
              className={`payment-all-cart-items ${
                hasOverflow ? 'overflowed' : ''
              }`}
              ref={cartDivRef}
            >
              {storeProductDataState.loading ? (
                <div className="payment-cart-">
                  <Skeleton
                    variant="rounded"
                    width={540}
                    height={110}
                    style={{ margin: '10px' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={540}
                    height={110}
                    style={{ margin: '10px' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={540}
                    height={110}
                    style={{ margin: '10px' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={540}
                    height={110}
                    style={{ margin: '10px' }}
                  />
                </div>
              ) : cartData &&
                Array.isArray(cartData) &&
                cartData.length >= 1 ? (
                cartData.map((cartItem, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    key={cartItem.addedOn}
                  >
                    <PaymentWishLIst
                      key={index}
                      cartItem={cartItem}
                      merchantId={merchantId}
                      cartData={cartData}
                      setUpdatingCart={setUpdatingCart}
                    />
                  </Grid>
                ))
              ) : (
                <p></p>
              )}
            </Grid>
            {isMobile && (
              <div className=" d-flex justify-content-center px-4">
                <div className="d-flex   border border-2 border-black rounded-2 w-100">
                  <Link
                    to={`/merchant/${merchantId}?orderMethod=${orderMethod}`}
                    className="payment-all-cart-add-more-products d-flex justify-content-center p-2 w-100 position-relative"
                  >
                    <p>Add More Products</p>
                    <div className="position-absolute end-0 me-2">
                      <img
                        src={AddIcon}
                        alt="delete"
                        style={{ margin: '0px 0px 0px 10px' }}
                      />
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="payment-stepper-checkout-item">
            <CouponTipSection
              setVisible={setVisible}
              visible={visible}
              userData={isAuthenticated}
              cartData={cartData}
            />
          </div>
        </div>
      ) : storeProductDataState.loading || updatingCart ? (
        <div className="loaderarea">
          <Loaderfile />
        </div>
      ) : (
        <div className="single-store-empty-wishlist">
          <div className="empty-wishlist-content">
            <img src={EmptyCart} alt="No-products" />
            <h1>YOUR CART IS EMPTY</h1>
            <p>Looks like you haven't added anything to your cart yet.</p>
            <Button className="continue-shopping-on-wishlist">
              <Link to={`/merchant/${merchantId}?orderMethod=${orderMethod}`}>
                Start Shopping
              </Link>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
