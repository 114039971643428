import React, { useState, useEffect, useMemo } from 'react';
import QuickveeLogo from '../../../Assets/Store/quickveeLogo.svg';
import { Button, Drawer, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '../../../Assets/Store/CouponInfo.svg';
import ContentCopyOutlinedIcon from '../../../Assets/Store/contentCopy.svg';
import ShoppingCartOutlinedIcon from '../../../Assets/Store/Cart_logo.svg';
import Cart from '../Wishlist/Cart';
import { useDispatch, useSelector } from 'react-redux';
import Validation from '../../../Constants/Validation';
import SearchBar from './SearchBar';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import { useNavigate } from 'react-router-dom';
import { setWishlist } from '../../../redux/features/Wishlist/wishlistSlice';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import QuickveeLogoSymbol from './../../../Assets/Store/Mask Group 5941.svg';
// import SingleProductPage from "../Products/SingleProductPage"
import Mask_Group from './../../../Assets/Store/Mask Group 5912.svg';
import { useMediaQuery } from '@mui/material';
import userLogo from './../../../Assets/Store/Mask Group 5930.svg';
import { Badge } from '@mui/material';
import FavouriteIcon from '../../../Assets/Store/WishListTopBar.svg';
import Cookies from 'js-cookie';

const SingleStoreTopBar = ({
  merchantId,
  orderType,
  cartVisible,
  toggleDrawer,
  setcartVisible,
  handleNavClick,
  navBar,
  setSingleProductDataPushUp,
  handleOverlayClickOpen,
  visible,
}) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { convertToIndianTime, formatDate, filterCartData } = Validation();
  const [couponVisibility, setCouponVisibility] = useState(false);
  const [couponCopied, setCouponCopied] = useState(false);
  // const [cart, setCart] = useState();
  const [singleStore, setSingleStore] = useState();
  const [varientData, setVarientData] = useState([]);
  const [cartData, setCartDataTop] = useState([]);
  const cartDataState = useSelector((state) => state.cart.cartData);
  const [showuserData, setShowuserData] = useState(false);
  const [userData, setUserData] = useState();
  const authUserData = useSelector((state) => state.authUser.UserData);
  const { isGuest } = useSelector((state) => state.authUser);

  const [singleStoreData, setSingleStoreData] = useState();
  const [singleStoreProductData, setSingleStoreProductData] = useState();
  const SingleStoreDataState = useSelector((state) => state.singleStores);

  const singleStoreProductDataState = useSelector(
    (state) => state.storeProductData,
  );

  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );

  // const VarientDataState = useSelector((state) => state.variantCart);
  const variantDataState = useSelector((state) => state.variant);
  const isMobile = useMediaQuery('(max-width:768px)');

  // useEffect(() => {
  //   setCart(cartDataState);
  // }, [cartDataState, toggleDrawer]);

  useEffect(() => {
    if (
      !singleStoreProductDataState.loading &&
      !variantDataState.loading &&
      !SingleStoreDataState.loading
    ) {
      setSingleStoreProductData(singleStoreProductDataState.storeProductData);
      setSingleProductDataPushUp(singleStoreProductDataState.storeProductData);
      setSingleStore(
        SingleStoreDataState &&
          SingleStoreDataState.storesData &&
          SingleStoreDataState.storesData.store_data,
      );
      setVarientData(variantDataState.variantCartData.data);
    }
  }, [
    singleStoreProductDataState.loading,
    singleStoreProductDataState.storeProductData,
    variantDataState,
    SingleStoreDataState.loading,
    SingleStoreDataState.storesData,
  ]);

  const handleLogout = () => {
    // console.log("logout")
    localStorage.removeItem('is_guest');
    localStorage.removeItem('guestDelivery_address');
    localStorage.removeItem('guestAddDeliveryAddress');
    localStorage.removeItem('userLoginData');
    localStorage.removeItem('is_guest');
    localStorage.removeItem('radius');
    Cookies.remove('userLoginData');
    Cookies.remove('is_guest');
    dispatch(setGuestLogin(''));

    dispatch(setAuthUser(null));
    dispatch(setAuthentication(false));
    dispatch(setWishlist([]));
    setUserData([]);
    dispatch(setCartData([]));
    const cartKey = `cart_${merchantId}`;
    localStorage.removeItem(cartKey);
    // const bogoCartKey = `bogo_cart_${merchantId}`;
    // localStorage.removeItem(bogoCartKey);
    Navigate(`/merchant/${merchantId}?orderMethod=${orderType}`);
  };

  useEffect(() => {
    if (authUserData && authUserData.id) {
      setUserData(authUserData);
    }
  }, [authUserData]);

  useEffect(() => {
    if (!SingleStoreDataState.loading && !singleStoreProductDataState.loading) {
      setSingleStoreData(SingleStoreDataState?.storesData?.store_data);
      setSingleStoreProductData(singleStoreProductDataState.storeProductData);
    }
  }, [
    SingleStoreDataState.loading,
    SingleStoreDataState.storesData,
    singleStoreProductDataState.loading,
    singleStoreProductDataState.storeProductData,
  ]);

  useEffect(() => {
    // console.log('search box cartDataState: ', cartDataState);
    // console.log('varientData: ', varientData);
    // console.log('cartProductDetails: ', cartProductDetails);
    if (cartDataState.length <= 0) {
      setCartDataTop([]);
      return;
    }
    if (cartDataState && varientData && cartProductDetails) {
      const allcartdata = filterCartData(
        cartDataState,
        varientData,
        cartProductDetails,
      );

      // console.log('allcartdata: ', allcartdata);
      setCartDataTop(allcartdata);
    }
  }, [
    singleStoreProductData,
    cartDataState,
    toggleDrawer,
    varientData,
    cartProductDetails,
  ]);

  const handleCouponCopyVisiblity = (event) => {
    event.stopPropagation();
    setCouponVisibility(!couponVisibility);
    handleProfileDataVisibleScreen();
  };
  const handleProfileDataVisible = (event) => {
    event.stopPropagation();
    setShowuserData(!showuserData);
    handleCouponCopyVisiblityScreen();
  };

  const handleProfileDataVisibleScreen = () => {
    setShowuserData(false);
  };
  const handleCouponCopyVisiblityScreen = () => {
    setCouponVisibility(false);
  };

  const handleCopyCouponContent = (event, text) => {
    event.stopPropagation();
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCouponCopied(true);
  };
  let coupontime_valid = singleStoreData
    ? convertToIndianTime(singleStoreData.coupon_details.time_valid)
    : null;
  let coupontime_expire = singleStoreData
    ? convertToIndianTime(singleStoreData.coupon_details.time_expire)
    : null;

  useEffect(() => {
    document.addEventListener('click', handleCouponCopyVisiblityScreen);
    document.addEventListener('click', handleProfileDataVisibleScreen);
    return () => {
      document.removeEventListener('click', handleCouponCopyVisiblityScreen);
      document.addEventListener('click', handleProfileDataVisibleScreen);
    };
  }, []);

  return (
    <>
      <div className="quickvee-single-store-top-bar">
        <div className="quickvee-logo-icon-single-store">
          {isMobile ? (
            <img
              className="menu-toogle"
              onClick={() => {
                handleNavClick();
                handleOverlayClickOpen();
              }}
              src={Mask_Group}
              alt="Mask_Group"
            />
          ) : (
            ''
          )}

          <Link to={`/merchant/${merchantId}?orderMethod=${orderType}`}>
            {isMobile ? (
              <>
                {' '}
                <img
                  src={QuickveeLogoSymbol}
                  height={44}
                  alt="Quickvee"
                  loading="lazy"
                />
              </>
            ) : (
              <>
                {' '}
                <img
                  src={QuickveeLogo}
                  height={44}
                  alt="Quickvee"
                  loading="lazy"
                />
              </>
            )}
          </Link>
        </div>
        {isMobile ? (
          ''
        ) : (
          <SearchBar merchantId={merchantId} orderType={orderType} />
        )}
        {singleStore && singleStore.coupon_details ? (
          <>
            {isMobile ? (
              ''
            ) : (
              <div className="quickvee-single-store-offer-coupon">
                <Button
                  onClick={handleCouponCopyVisiblity}
                  className="quickvee-single-store-offer-button"
                  style={
                    couponVisibility
                      ? { boxShadow: '0px 6px 12px #00000029' }
                      : { '': '' }
                  }
                >
                  <span style={{ marginRight: '5px' }}>Offer:</span>
                  <p> {singleStore.coupon_details.name}</p>
                  <img
                    src={InfoOutlinedIcon}
                    alt="coupon-info"
                    style={{ marginLeft: '12px' }}
                    loading="lazy"
                  />
                </Button>
                {couponVisibility && (
                  <div
                    className="quickvee-single-store-offer-detail"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span className="usecoupon-text">Use Coupon Code</span>
                    <Tooltip
                      title={
                        !couponCopied
                          ? 'Copy Coupon to Clipboard'
                          : 'Coupon Copied'
                      }
                      arrow
                      leaveDelay={500}
                    >
                      <Button
                        className="coupon-copytext"
                        onClick={(e) =>
                          handleCopyCouponContent(
                            e,
                            singleStore.coupon_details.name,
                          )
                        }
                      >
                        {singleStore.coupon_details.name}
                        <img
                          src={ContentCopyOutlinedIcon}
                          alt="content-copy"
                          style={{ marginLeft: '20px' }}
                          loading="lazy"
                        />
                      </Button>
                    </Tooltip>

                    <span
                      style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Valid Till -{' '}
                      {formatDate(singleStore.coupon_details.date_expire)}
                    </span>
                    <span
                      style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                    >
                      {' '}
                      {/* From {coupontime_valid ? coupontime_valid : ''} To{' '}
                      {coupontime_expire ? coupontime_expire : ''} */}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          ''
        )}

        <div className="quickvee-single-store-login-and-wishlist-button">
          {isMobile ? (
            <div>
              {isGuest !== 'yes' ? (
                <Link
                  to={`/wishlist/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
                  className={
                    visible === 'wishlist'
                      ? 'quickvee-single-store-link-active quickvee-single-store-link'
                      : 'quickvee-single-store-link'
                  }
                >
                  <p className="wishlist-icon"></p>
                  <img src={FavouriteIcon} alt="favouriteIcon" />
                </Link>
              ) : null}
            </div>
          ) : (
            ''
          )}
          <div className="topBarUserAndCartLogo">
            {userData && userData.id ? (
              <div
                style={{ cursor: 'pointer' }}
                className="top-bar-button d-flex align-items-center"
                onClick={handleProfileDataVisible}
              >
                {isMobile ? (
                  <img
                    src={userLogo}
                    className="mobile-user-logo"
                    alt="userLogo"
                  />
                ) : (
                  <span className=""> {userData.name.split(' ')[0]}</span>
                )}

                {isMobile ? (
                  ''
                ) : (
                  <span className="online-o-page-profile-drop-btn">
                    <KeyboardArrowDownIcon />
                  </span>
                )}

                {/* <div
    className="online-o-page-profile-box"
    style={{ display: showuserData ? 'inline-table' : 'none' }}
  >
    {
      localStorage.getItem('is_guest') !=="yes" ? <>
      
      <Link className="profile-bar-link" to='/order/profile'  state={{ merchantId: merchantId , orderMethod:OrderMethod }} >
      My Profile
    </Link>
    <Link className="profile-bar-link" to="/order/order-history" state={{ merchantId: merchantId , orderMethod:OrderMethod  }} >
      Order
    </Link>
      
       </> : ''
    }
  
    <Button
      className="profile-bar-link profile-bar-link-logout"
      onClick={() => handleLogout()}
    >
      Logout
    </Button>
  </div>
*/}
                <div
                  className="online-o-page-profile-box"
                  style={{ display: showuserData ? 'inline-table' : 'none' }}
                >
                  {isGuest !== 'yes' ? (
                    <>
                      <Link
                        className="profile-bar-link"
                        to="/order/profile"
                        state={{
                          merchantId: merchantId,
                          orderMethod: orderType,
                        }}
                      >
                        My Profile
                      </Link>
                      <Link
                        className="profile-bar-link"
                        to="/order/order-history"
                        state={{
                          merchantId: merchantId,
                          orderMethod: orderType,
                        }}
                      >
                        Order
                      </Link>
                    </>
                  ) : (
                    ''
                  )}

                  <div
                    className="profile-bar-link profile-bar-link-logout"
                    style={{
                      cursor: 'pointer',
                      borderBottom: isGuest !== 'yes' ? 'none' : 'none',
                    }}
                    onClick={() => handleLogout()}
                    id="Logout-btn"
                  >
                    Logout
                  </div>
                </div>
              </div>
            ) : (
              <>
                {isMobile ? (
                  <Button>
                    <Link
                      to="/customer-login"
                      state={{
                        merchantId: merchantId,
                        orderMethod: singleStore?.orderMethod,
                        page: 'onlineordering',
                      }}
                      // className="quickvee-customer-login"
                    >
                      <img src={userLogo} alt="userLogo" />
                    </Link>
                  </Button>
                ) : (
                  <div style={{ display: 'flex', width: '118px' }}>
                    <Link
                      to="/customer-login"
                      state={{
                        merchantId: merchantId,
                        orderMethod: singleStore?.orderMethod,
                        page: 'onlineordering',
                      }}
                      className="quickvee-customer-login"
                    >
                      Log In
                    </Link>
                  </div>
                )}
              </>
            )}
            {isMobile ? (
              <Badge
                badgeContent={cartData ? cartData.length : 0}
                // badgeContent={cartDataState ? cartDataState.length : 0}
                color="primary"
              >
                <img
                  className="moble-cart-icon"
                  onClick={toggleDrawer}
                  src={ShoppingCartOutlinedIcon}
                  alt="shopping-cart"
                  loading="lazy"
                />
              </Badge>
            ) : (
              <div
                style={{ cursor: 'pointer' }}
                className="quickvee-single-store-cart-button d-flex flex-nowrap"
                onClick={toggleDrawer}
              >
                <img
                  src={ShoppingCartOutlinedIcon}
                  height={30}
                  width={30}
                  style={{ marginRight: '5px' }}
                  alt="shopping-cart"
                  loading="lazy"
                />
                {cartData ? cartData.length : 0}
                {/* <span> {cartDataState ? cartDataState.length : 0}</span> */}
              </div>
            )}

            <Drawer
              anchor={isMobile ? 'bottom' : 'right'}
              open={cartVisible}
              onClose={toggleDrawer}
            >
              <Cart
                toggleDrawer={toggleDrawer}
                orderType={orderType}
                merchantId={merchantId}
              />
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleStoreTopBar;
