import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  cartData: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cartData = [...state.cartData, ...action.payload];
    },
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
    removeCartItem: (state, action) => {
      // removing all similar products
      // state.cartData = state.cartData.filter(
      //   (item) => item.cartProductId !== action.payload.cartProductId,
      // );

      // only removing single product
      state.cartData = state.cartData.filter(
        (item) => item.addedOn !== action.payload.addedOn,
      );
    },
    updateCartItem: (state, action) => {
      // const index = state.cartData.findIndex(
      //   // (item) => item.cartProductId === action.payload.cartProductId,
      //   (item) => item.addedOn === action.payload.addedOn,
      // );
      // if (index !== -1) {
      //   // You can update the quantity in action.payload.quantity or use another value
      //   state.cartData[index].quantity = action.payload.quantity;
      //   state.cartData[index].isOutOfStock = false;
      //   state.cartData[index].msg = '';
      //   state.cartData[index].isDisabled = false;
      // }

      // console.log('update redux cart slice: ', state.cartData, action.payload);

      if (action.payload.type === 'increment') {
        const newProduct = state.cartData.find(
          (item) => item.cartProductId === action.payload.cartProductId,
        );
        // console.log('newProduct: ', newProduct);
        state.cartData = [
          ...state.cartData,
          {
            ...newProduct,
            addedOn:
              state.cartData.length > 0
                ? state.cartData[state.cartData.length - 1].addedOn + 1
                : 1,
          },
        ];
      } else {
        state.cartData = state.cartData.filter(
          (item) => item.addedOn !== action.payload.addedOn,
        );
      }
    },
    updateCartItemfromSingleProduct: (state, action) => {
      const index = state.cartData.findIndex(
        (item) => item.productId === action.payload.productId,
      );
      if (index !== -1) {
        // You can update the quantity in action.payload.quantity or use another value
        state.cartData[index].quantity = action.payload.quantity;
      }
    },
    updateCartWithVariants: (state, action) => {
      // console.log('updateCartWithVariants: ', action.payload);
      state.cartData = action.payload;
    },
  },
});

export const {
  setCartData,
  addToCart,
  removeCartItem,
  updateCartItem,
  updateCartItemfromSingleProduct,
  updateCartWithVariants,
} = cartSlice.actions;

export default cartSlice.reducer;
