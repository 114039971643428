import { Button } from '@mui/material';
import AddOutlinedIcon from '../../../../Assets/Home/plusWhite.svg';
import RemoveOutlinedIcon from '../../../../Assets/Home/minusWhite.svg';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Validation from '../../../../Constants/Validation';

export const MinusWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_3262"
            data-name="Rectangle 3262"
            width="16"
            height="16"
            transform="translate(-566 2518)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="minus" transform="translate(566 -2518)" clipPath="url(#clipPath)">
        <line
          id="Line_689"
          data-name="Line 689"
          y2="11.25"
          transform="translate(-552.375 2526) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const PlusWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_3261"
            data-name="Rectangle 3261"
            width="16"
            height="16"
            transform="translate(-590 2518)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g id="plus" transform="translate(590 -2518)" clipPath="url(#clipPath)">
        <line
          id="Line_686"
          data-name="Line 686"
          y2="11.25"
          transform="translate(-582 2520.375)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <line
          id="Line_687"
          data-name="Line 687"
          y2="11.25"
          transform="translate(-576.375 2526) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

const ProductActionButtons = ({
  productConditions,
  singleVariantData,
  updateProductQ,
  toggleDrawer,
  productQuantity,
  handleUpdateQuantity,
  handleAddToCart,
  disableQuantityUpdate,
  singleProductDataState,
  changingVariant,

  isDisabled,
  setIsDisabled,
  productIsOutOfStock,
  outOfStockCondition,
}) => {
  const currentSingleVariant = useSelector((state) => state.variant);
  // const variantCartDataState = useSelector((state) => state.variantCart);
  const cartDataState = useSelector((state) => state.cart.cartData);
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );

  // const [isDisabled, setIsDisabled] = useState(false);

  const { filterCartData } = Validation();

  // Checking if Products is a PQ product and any other similar PQ product is in cart with PQ qty limit.
  useEffect(() => {
    if (
      singleProductDataState?.singleProductData?.option_data &&
      currentSingleVariant.variantCartData.data &&
      currentSingleVariant.variantData &&
      cartDataState &&
      cartProductDetails
    ) {
      const productPurchaseQTY =
        +currentSingleVariant.variantData?.purchase_qty;
      const productId = currentSingleVariant.variantData?.product_id;
      const productVariantId = currentSingleVariant.variantData?.id;

      // console.log("productPurchaseQTY: ", productPurchaseQTY)
      // console.log('currentSingleVariant: ', currentSingleVariant);
      // console.log('variantCartDataState: ', variantCartDataState);

      const getCartProducts = () => {
        if (
          cartDataState &&
          currentSingleVariant?.variantCartData.data &&
          cartProductDetails
        ) {
          const data = filterCartData(
            cartDataState,
            currentSingleVariant?.variantCartData.data,
            cartProductDetails,
          );

          return data ? data : [];
        }
      };

      const cartProducts = getCartProducts();
      // console.log('cartProducts: ', cartProducts);

      const variantCartProductsInCart = [];
      currentSingleVariant.variantCartData.data?.forEach((prod) => {
        cartProducts.forEach((cartProduct) => {
          if (
            prod?.id === cartProduct?.variantId &&
            prod?.product_id === cartProduct?.productId
          ) {
            variantCartProductsInCart.push(prod);
          }
        });
      });

      // console.log('variantCartProductsInCart: ', variantCartProductsInCart);

      // Same products but different variants from the cart
      /* 
        eg: In cart - Red & Blue variants from same product, If I'm on red product page, this otherSamePQProducts array will give 
        us blue products from cart 
      */
      const otherSamePQProducts = variantCartProductsInCart.filter(
        (prod) =>
          prod.product_id === productId &&
          +prod.purchase_qty === productPurchaseQTY &&
          prod.id !== productVariantId,
      );
      // console.log('otherSamePQProducts: ', otherSamePQProducts);

      // 2. get total qty added in cart.. if same as PQ. then disable current selected product.
      if (otherSamePQProducts.length > 0) {
        // let totalQtyInCart = 0;

        const sameProductIds = otherSamePQProducts.map((item) => item.id);
        const sameProductFromCart = cartProducts.filter((item) =>
          sameProductIds.includes(item.variantId),
        );
        // console.log('sameProductIds: ', sameProductIds);
        // console.log('sameProductFromCart: ', sameProductFromCart);

        let totalQtyInCart = sameProductFromCart.reduce(
          (acc, curr) => (acc += +curr.selectedQuantity),
          0,
        );

        // otherSamePQProducts.forEach((prod) => {
        //   cartProducts.forEach((cartProduct) => {
        //     if (cartProduct.variantId === prod.id) {
        //       totalQtyInCart = totalQtyInCart + cartProduct.selectedQuantity;
        //     }
        //   });
        // });
        // console.log('totalQtyInCart: ', totalQtyInCart);

        // 1. if already in cart products qty is same as PQ limit.. disable this current product.
        if (+totalQtyInCart === +productPurchaseQTY) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else {
        setIsDisabled(false);
      }
    }
  }, [
    currentSingleVariant,
    cartDataState,
    cartProductDetails,
    singleProductDataState?.singleProductData?.option_data,
  ]);

  // // if product qty is 0
  // // if product stock continue is false
  // // if product track quantity is on
  // // then that product is out of stock
  // const productIsOutOfStock = useMemo(() => {
  //   return (
  //     productConditions.isQuantityZeroOrLess &&
  //     productConditions.isProductStockContinued &&
  //     productConditions.productTrackQuantity
  //   );
  // }, [productConditions]);

  // Product is not Out of stock, not already present in cart, Not disabled, then only we can Add it in Cart
  const addToCartCondition = useMemo(() => {
    const bool =
      !productConditions.productIsDisabled &&
      !productIsOutOfStock &&
      !updateProductQ &&
      !isDisabled;

    return bool;
  }, [
    productConditions.productIsDisabled,
    productIsOutOfStock,
    updateProductQ,
    isDisabled,
  ]);

  // // If product is disabled,
  // // if its out of stock,
  // const outOfStockCondition = useMemo(() => {
  //   return (
  //     productConditions.productIsDisabled ||
  //     productIsOutOfStock ||
  //     (!singleVariantData && productIsOutOfStock) ||
  //     (isDisabled && !updateProductQ)
  //   );
  // }, [
  //   productConditions.productIsDisabled,
  //   singleVariantData,
  //   productIsOutOfStock,
  //   isDisabled,
  //   updateProductQ,
  // ]);

  return (
    <>
      {/*showcasing Increase & Decrease qty buttons for a product */}
      {addToCartCondition ? (
        <div className="quickvee-single-product-add-update">
          <button
            className="add-minuus-button"
            disabled={productQuantity <= 1 ? true : false}
            onClick={() =>
              handleUpdateQuantity(productQuantity - 1, 'decrement')
            }
          >
            {' '}
            <img src={RemoveOutlinedIcon} alt="remove-icon" />{' '}
          </button>
          <span> {productQuantity} </span>
          <button
            className="add-minuus-button"
            disabled={disableQuantityUpdate}
            onClick={() =>
              handleUpdateQuantity(productQuantity + 1, 'increment')
            }
          >
            {' '}
            <img src={AddOutlinedIcon} alt="add-icon" />
          </button>
          <Button
            className="single-patc-btn"
            onClick={() => handleAddToCart()}
            disabled={changingVariant}
          >
            Add to Cart
          </Button>
        </div>
      ) : null}

      {/* Showing Out of Stock button */}
      {outOfStockCondition ? (
        <Button className="out-of-stock-btn-single-product-page">
          Out of Stock
        </Button>
      ) : null}

      {/* showcing Already in Cart button */}
      {updateProductQ && !outOfStockCondition ? (
        <Button className="go-to-cart-btn" onClick={() => toggleDrawer()}>
          Go to Cart
        </Button>
      ) : null}
    </>
  );
};

export default ProductActionButtons;
