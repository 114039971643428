import React, { useEffect, useState } from 'react';
import Zoom from 'react-img-zoom';

const ImageZoom = ({ merchant_id, image }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [merchant_id, image]);

  return (
    <>
      <div className="single-product-zoom-div">
        <Zoom
          key={key}
          img={image}
          zoomScale={2}
          transitionTime={0.5}
          width={400}
          height={428}
        />
      </div>
    </>
  );
};

export default ImageZoom;
