import React, { useEffect, useMemo, useState } from 'react';
import CrossIcon from '../../../Assets/Store/cross.svg';
import { Button } from '@mui/material';
import EmptyCart from '../../../Assets/Store/emptyCart.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Validation from '../../../Constants/Validation';
import SingleCartItem from './SingleCartItem';
import DeliveryPriceModal from './DeliveryPriceModal';
import { handleBogoDeals } from '../../../utils';

const Cart = ({ toggleDrawer, orderType, merchantId }) => {
  const { filterCartData, calculateTotalPrice } = Validation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [cart, setCart] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [singleStoreProductData, setSingleStoreProductData] = useState();
  const [singleStore, setSingleStore] = useState();
  const [varientData, setVarientData] = useState([]);
  const [cartData, setCartData] = useState([]);

  const cartDataState = useSelector((state) => state.cart.cartData);
  const SingleStoreProductDataState = useSelector(
    (state) => state.storeProductData,
  );
  const SingleStoreDataState = useSelector((state) => state.singleStores);
  // const VarientDataState = useSelector((state) => state.variantCart);
  const variantDataState = useSelector((state) => state.variant);
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );
  const bogoList = useSelector((state) => state.bogoList);

  useEffect(() => {
    // console.log('VarientDataState: ', VarientDataState);
    // console.log('SingleStoreProductDataState: ', SingleStoreProductDataState);
    // console.log('SingleStoreDataState: ', SingleStoreDataState);
    if (
      !SingleStoreProductDataState.loading &&
      !variantDataState.loading &&
      !SingleStoreDataState.loading
    ) {
      setSingleStoreProductData(SingleStoreProductDataState.storeProductData);
      setSingleStore(
        SingleStoreDataState &&
          SingleStoreDataState.storesData &&
          SingleStoreDataState.storesData.store_data,
      );
      setVarientData(variantDataState.variantCartData.data);
    }
  }, [SingleStoreProductDataState, variantDataState, SingleStoreDataState]);

  useEffect(() => {
    // console.log('cartDataState: ', cartDataState);
    // console.log('cartProductDetails: ', cartProductDetails);
    // console.log('varientData: ', varientData);
    if (cartDataState.length <= 0) {
      setCartData([]);
      return;
    }

    if (cartDataState && cartProductDetails && varientData) {
      const allcartdata = filterCartData(
        cartDataState,
        varientData,
        cartProductDetails,
      );

      // check for bogo deals products, update the cart data. and set it with updated one.
      // const bogoCartKey = `bogo_cart_${merchantId}`;
      // const bogoCart = JSON.parse(localStorage.getItem(bogoCartKey)) || [];

      const cartKey = `cart_${merchantId}`;
      const cartData = JSON.parse(localStorage.getItem(cartKey)) || [];

      // console.log('cartData: ', cartData);
      // console.log('allcartdata: ', allcartdata);

      const newArray = [];
      // adding added on key
      allcartdata.forEach((item, index) => {
        newArray.push({
          ...item,
          addedOn:
            cartData[index] && cartData[index]?.addedOn
              ? cartData[index]?.addedOn
              : cartData[cartData.length - 1]?.addedOn + 1,
        });
      });

      // console.log('newArray: ', newArray);

      // console.log('hehe bogoCart: ', bogoCart);

      const afterBogoCart = handleBogoDeals(newArray, bogoList.bogoList);
      const isBogoApplied = afterBogoCart.some(
        (item) => item.bogoDiscountApplied,
      );

      // console.log('afterBogoCart: ', afterBogoCart);

      if (bogoList && bogoList?.bogoList?.length > 0 && isBogoApplied) {
        setCartData(afterBogoCart);
      } else {
        setCartData(newArray);
      }
    }
  }, [
    bogoList,
    merchantId,
    singleStoreProductData,
    cartDataState,
    cartProductDetails,
    toggleDrawer,
    varientData,
  ]);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(cartData));
  }, [cartData]);

  const disableCheckout = useMemo(() => {
    return cartData?.some((element) => element?.isOutOfStock);
  }, [cartData]);

  const isPQLimitReached = useMemo(() => {
    return cartData?.some(
      (element) => element?.isOutOfStock && element?.pqLimitReached,
    );
  }, [cartData]);

  return (
    <>
      <div className="quickvee-store-topbar-cart">
        <div className="quickvee-store-cart-header">
          <h1>
            My Cart
            {/* <span> ({cartDataState ? cartDataState.length : 0}) </span> */}
            <span> ({cartData ? cartData.length : 0}) </span>
          </h1>
          <div style={{ cursor: 'pointer' }} onClick={toggleDrawer}>
            {' '}
            <img
              src={CrossIcon}
              width={25}
              height={25}
              alt="close-wishlist"
            />{' '}
          </div>
        </div>
        {cartData && cartData.length > 0 ? (
          <div className="quickvee-store-cart-items">
            {cartData &&
              Array.isArray(cartData) &&
              cartData.map(
                (cartItem, index) =>
                  cartItem && (
                    <SingleCartItem
                      key={index}
                      cartItem={cartItem}
                      singleStore={singleStore}
                      cartData={cartData}
                      merchantId={merchantId}
                    />
                  ),
              )}
          </div>
        ) : (
          <div className="single-store-empty-cart">
            <div className="empty-cart-content">
              <img src={EmptyCart} alt="No-products" />
              <h1>YOUR CART IS EMPTY</h1>
              <p>Looks like you haven't added anything to your cart yet</p>{' '}
              <Button
                onClick={toggleDrawer}
                className="continue-shopping-on-cart"
              >
                {' '}
                <Link to={`/merchant/${merchantId}?orderMethod=${orderType}`}>
                  {' '}
                  Shop Now{' '}
                </Link>{' '}
              </Button>
            </div>
          </div>
        )}

        <div className="quickvee-store-cart-checkout-btn">
          {isPQLimitReached ? (
            <p className="no-stock-variant-product mb-2 text-center">
              Purchase Quantity Limit Reached!
            </p>
          ) : disableCheckout ? (
            <p className="no-stock-variant-product mb-2 text-center">
              Cannot proceed with Out of Stock products!
            </p>
          ) : null}

          {totalPrice && totalPrice > 0 ? (
            singleStore?.orderMethod === 'delivery' ? (
              Number(singleStore?.min_delivery_amt) > Number(totalPrice) ? (
                <DeliveryPriceModal
                  minamount={singleStore.min_delivery_amt}
                  buttonText={`${
                    totalPrice && parseFloat(totalPrice).toFixed(2)
                  } `}
                  disableCheckout={disableCheckout}
                />
              ) : (
                <>
                  <Link
                    to={`/payment-cart/${
                      singleStore && singleStore.merchant_id
                    }?orderMethod=${orderType}`}
                    onClick={(e) => {
                      if (disableCheckout) {
                        e.preventDefault();
                      }
                    }}
                    className={`${disableCheckout ? 'disable-link' : ''}`}
                  >
                    {' '}
                    Checkout With Delivery $
                    {totalPrice && parseFloat(totalPrice).toFixed(2)}{' '}
                  </Link>
                </>
              )
            ) : (
              <>
                <Link
                  to={`/payment-cart/${
                    singleStore && singleStore.merchant_id
                  }?orderMethod=${orderType}`}
                  onClick={(e) => {
                    if (disableCheckout) {
                      e.preventDefault();
                    }
                  }}
                  className={`${disableCheckout ? 'disable-link' : ''}`}
                >
                  {' '}
                  Checkout With Pickup $
                  {totalPrice && parseFloat(totalPrice).toFixed(2)}{' '}
                </Link>
              </>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
