import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, TextField } from '@mui/material';
import ChangePasswordLogic from './ChangePasswordLogic';

const ChangePassword = () => {
  const inputFileRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    values.password = '';
    values.oldpassword = '';
    values.confirmpassword = '';
    values.errors.password = '';
    values.errors.confirmpassword = '';
    setsubmitmessage('');
  };
  const inputRefs = useRef({});
  const {
    ChangePasswordInput,
    handleSubmitChangePassword,
    values,
    submitmessage,
    handleKeyDown,
    setsubmitmessage,
  } = ChangePasswordLogic({ handleClose });

  const [show, setShow] = useState(false);
  const [oldshow, setOldShow] = useState(false);
  const [reshow, setReShow] = useState(false);

  if (values.password !== values.confirmpassword) {
    values.errors.confirmpassword = 'Re-enter password not matching';
  }
  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  return (
    <>
      <div>
        <div
          style={{ cursor: 'pointer' }}
          className="modal-main-button BasicInfo-btn-padding"
          onClick={handleOpen}
        >
          Change Password
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal">
            <div id="modal-modal-title">
              <span>Change Password</span>
              <CloseIcon onClick={handleClose} className="modal-close-icon" />
            </div>

            <form id="modal-modal-description" className="modal-forms">
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div>
                      <TextField
                        label=" Old Password "
                        className="input-field"
                        variant="outlined"
                        autoComplete="off"
                        type={oldshow === true ? 'text' : 'password'}
                        onChange={ChangePasswordInput}
                        name="oldpassword"
                        value={values.oldpassword}
                        // inputProps={{
                        //   'data-field': 'oldpassword',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['oldpassword'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.oldpassword,
                        // }}
                      ></TextField>

                      {/* {submitmessage ?  <span className="input-error error-on-submit">{submitmessage}</span> : ""} */}
                      <span
                        className="show-hide-button"
                        onMouseDown={() => setOldShow(true)}
                        onTouchStart={() => setOldShow(true)}
                        onMouseUp={() => setOldShow(false)}
                        onTouchEnd={() => setOldShow(false)}
                      >
                        {' '}
                        {oldshow === true && values.oldpassword.length > 0
                          ? 'Hide'
                          : 'Show'}{' '}
                      </span>
                    </div>
                  </FormControl>
                  <span className="input-error">
                    {values.errors.oldpassword
                      ? values.errors.oldpassword
                      : submitmessage}
                  </span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-12"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div className="modal-form-single-item">
                      <TextField
                        label="New Password"
                        className="input-field"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        type={show === true ? 'text' : 'password'}
                        onChange={ChangePasswordInput}
                        name="password"
                        value={values.password}
                        // inputProps={{
                        //   'data-field': 'password',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['password'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.password,
                        // }}
                      ></TextField>

                      <span
                        className="show-hide-button"
                        onMouseDown={() => setShow(true)}
                        onTouchStart={() => setShow(true)}
                        onMouseUp={() => setShow(false)}
                        onTouchEnd={() => setShow(false)}
                      >
                        {' '}
                        {show === true && values.password.length > 0
                          ? 'Hide'
                          : 'Show'}{' '}
                      </span>
                    </div>
                  </FormControl>
                  <span className="input-error">{values.errors.password}</span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-12"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div className="modal-form-single-item">
                      <TextField
                        label=" Re-Enter New Password "
                        className="input-field"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        type={reshow === true ? 'text' : 'password'}
                        onChange={ChangePasswordInput}
                        name="confirmpassword"
                        value={values.confirmpassword}
                        onKeyDown={handleKeyDown}
                        // inputProps={{
                        //   'data-field': 'confirmpassword',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['confirmpassword'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.confirmpassword,
                        // }}
                      ></TextField>

                      <span
                        className="show-hide-button"
                        onMouseDown={() => setReShow(true)}
                        onTouchStart={() => setReShow(true)}
                        onMouseUp={() => setReShow(false)}
                        onTouchEnd={() => setReShow(false)}
                      >
                        {' '}
                        {reshow === true && values.confirmpassword.length > 0
                          ? 'Hide'
                          : 'Show'}{' '}
                      </span>
                    </div>
                  </FormControl>
                  <span className="input-error">
                    {values.errors.confirmpassword}
                  </span>
                </div>
              </div>

              <div className="modal-footer">
                <Button className="modal-cancle-btn" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  className="modal-save-btn"
                  onClick={handleSubmitChangePassword}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ChangePassword;
