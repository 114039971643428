import React, { useState, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import RemoveCartIcon from '../../../Assets/Payment/RemoveCart.svg';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RemoveCartItemModal = ({ handleDeleteCartProduct, cartItem }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleDeleteCartProduct(cartItem);
    handleClose();
  };

  return (
    <>
      <button style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectangle_3261"
                data-name="Rectangle 3261"
                width="16"
                height="16"
                transform="translate(-590 2518)"
                fill="none"
                stroke="#707070"
                strokeWidth="1"
              />
            </clipPath>
          </defs>
          <g
            id="plus"
            transform="translate(590 -2518)"
            clipPath="url(#clipPath)"
          >
            <line
              id="Line_687"
              data-name="Line 687"
              y2="11.25"
              transform="translate(-576.375 2526) rotate(90)"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
            />
          </g>
        </svg>
      </button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div"
      >
        <div className="payment-modal">
          <div className="payment-modal-content">
            <img src={RemoveCartIcon} alt={`wishlist-icon`} loading="lazy" />
            <span>
              Are You Sure You Want To <br /> Remove This Product From Cart?
            </span>
          </div>
          <div className="payment-modal-button">
            <button onClick={handleClose}>Cancel</button>
            <button
              onClick={handleSubmit}
              style={{ background: ' #FF4040', color: '#fff' }}
            >
              Remove
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RemoveCartItemModal;
