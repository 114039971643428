// export function formatNumber(input) {
//   if (!input) return '0.00';

//   // Parse input as a number
//   const num = parseFloat(input);

//   // Round to 3 decimal places and convert to string
//   let rounded = num.toFixed(3);

//   // Remove unnecessary trailing zeros
//   rounded = rounded.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');

//   return rounded;
// }

export function formatNumber(input) {
  if (!input) return '0.00';

  // Parse input as a number
  const num = parseFloat(input);

  // Round to 3 decimal places
  let rounded = num.toFixed(3);

  // Ensure the result has at least two decimal places
  rounded = rounded.replace(/(\.\d{2})0+$/, '$1'); // Remove trailing zeros after the second decimal place
  rounded = rounded.replace(/(\.\d{1})$/, '$10'); // Add a zero if there's only one digit after the decimal

  return rounded;
}

const isGreaterThan10 = (num) => (+num >= 10 ? num : `0${num}`);

export const formatDate = (inputDate, type) => {
  const parsedTime = new Date(inputDate);
  if (isNaN(parsedTime.getTime())) {
    return <div>Error: Invalid time format</div>;
  }

  const day = parsedTime.getDate();
  const month = parsedTime.toLocaleString('default', { month: 'short' });
  const year = parsedTime.getFullYear();
  let hours = parsedTime.getHours();
  const minutes = parsedTime.getMinutes();
  const seconds = parsedTime.getSeconds();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  let formattedTime = `${month} ${day}, ${year} ${isGreaterThan10(
    hours,
  )}:${isGreaterThan10(minutes)}:${isGreaterThan10(seconds)} ${meridiem}`;

  if (type === 'expiry-date') {
    formattedTime = `${month} ${day}, ${year}`;
  }

  if (type === 'future-order') {
    formattedTime = `${month} ${day}, ${year} ${isGreaterThan10(
      hours,
    )}:${isGreaterThan10(minutes)} ${meridiem}`;
  }
  return formattedTime;
};

export function formatDateTimeOnly(dateTime, usingFor) {
  const date = new Date(dateTime);

  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format
  hours = String(hours).padStart(2, '0'); // Add leading zero

  if (usingFor === 'createdDateInSaveOrder') {
    // console.log('Date: ', date);
    const seconds = date.getSeconds();
    const hoursIn24Format = date.getHours();
    return `${year}-${month}-${day} ${hoursIn24Format}:${minutes}:${seconds}`;
  } else {
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }
}

export const formatCurrency = (amount) => {
  const formattedAmount = Math.abs(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return amount < 0 ? `-${formattedAmount}` : formattedAmount;
};

export const getRoundValue = (value) =>
  value && !isNaN(value)
    ? parseFloat((Math.round(value * 100) / 100).toFixed(2))
    : 0;

export function prioritizeNumber(numbers) {
  // Helper functions to classify numbers
  const isDecimal = (num) => !Number.isInteger(num);
  const isOdd = (num) => Math.abs(num % 2) === 1;
  const isEven = (num) => num % 2 === 0;

  // Prioritize by categories
  const oddDecimals = numbers.filter(
    (num) => isDecimal(num) && isOdd(Math.floor(num)),
  );
  const oddWholeNumbers = numbers.filter(
    (num) => !isDecimal(num) && isOdd(num),
  );
  const evenDecimals = numbers.filter(
    (num) => isDecimal(num) && isEven(Math.floor(num)),
  );
  const evenWholeNumbers = numbers.filter(
    (num) => !isDecimal(num) && isEven(num),
  );

  // Return the first available number from each category in order of priority
  if (oddDecimals.length > 0) return oddDecimals[0];
  if (oddWholeNumbers.length > 0) return oddWholeNumbers[0];
  if (evenDecimals.length > 0) return evenDecimals[0];
  if (evenWholeNumbers.length > 0) return evenWholeNumbers[0];

  // Return null if no numbers are provided
  return 0;
}

export function adjustPrices(
  products = [],
  diffAmount = 0,
  type,
  discountAppliedMore = false,
) {
  // console.log('diff amount: ', diffAmount, type);

  // if discountAppliedMore is true then increase the products price and decrease the discount value

  // Sort products by price in descending order
  const sortedProducts = [...products].sort((a, b) => b.price - a.price);

  // Work with the absolute value for calculation
  let remainingDiff = Math.abs(diffAmount);
  // console.log('remaining diff: ', remainingDiff);

  // Adjust the prices and maintain the original product structure
  const adjustedProducts = sortedProducts.map((product, index) => {
    if (remainingDiff === 0) return product;
    // console.log('product: ', product);

    const splitAmount = parseFloat(
      (remainingDiff / (sortedProducts.length - index)).toFixed(2),
    );
    // console.log('splitAmount: ', splitAmount, sortedProducts.length, index);

    // Deduct split amount from the current price
    const deduction = Math.min(splitAmount, remainingDiff);
    // console.log('deduction: ', deduction);
    // const newPrice =
    //   diffAmount < 0
    //     ? getRoundValue(product.price - deduction)
    //     : getRoundValue(product.price + deduction);

    // const newPrice = getRoundValue(
    //   type === 'lpDiscountAmount'
    //     ? product.price + deduction
    //     : product.price - deduction,
    // );

    const newPrice = getRoundValue(
      discountAppliedMore
        ? product.price + deduction
        : product.price - deduction,
    );
    // console.log('newPrice: ', newPrice);

    // Update the remaining difference
    remainingDiff -= deduction;
    // console.log('remainingDiff: ', remainingDiff);

    // console.log('product price: ', product.price);
    // console.log('product[type]: ', product[type], type);

    const typePrice =
      product.price -
      parseFloat(parseFloat(newPrice).toFixed(2)) +
      parseFloat(product[type]);
    // console.log('type price: ', typePrice);

    // debugger;
    // Return the updated product with adjusted price
    return {
      ...product,
      // oldPrice: product.price,
      // price: parseFloat(newPrice.toFixed(2)),
      pennyFixedPrice: parseFloat(parseFloat(newPrice).toFixed(2)),
      [type]: parseFloat(parseFloat(typePrice).toFixed(2)),
    };
  });

  return adjustedProducts;
}

export const deductCouponDiscount = (itemPrice, percentageDeducCoupon) => {
  const isCouponApplied =
    parseFloat(percentageDeducCoupon) > 0
      ? parseFloat(percentageDeducCoupon)
      : 0;
  // console.log('isCouponApplied: ', isCouponApplied, itemPrice);
  const a = itemPrice - (itemPrice * isCouponApplied) / 100;
  // return parseFloat(parseFloat(a).toFixed(2));
  return parseFloat(getRoundValue(a));
};

export const deductLoyaltyPointsDiscount = (
  itemPrice,
  percentageDeducCoupon,
  percentageDeducLoy,
) => {
  const a = deductCouponDiscount(itemPrice, percentageDeducCoupon);

  const isLPApplied =
    parseFloat(percentageDeducLoy) > 0 ? parseFloat(percentageDeducLoy) : 0;
  const b = a - (a * isLPApplied) / 100;

  // return parseFloat(parseFloat(b).toFixed(2));
  return parseFloat(getRoundValue(b));
  // return parseFloat(b);
};

export function isDealActive(startDate, endDate) {
  const currentDate = new Date(); // Get the current date
  // const start = new Date(startDate); // Convert startDate to a Date object
  // const end = new Date(endDate); // Convert endDate to a Date object
  const start = new Date(`${startDate}T00:00:00`); // Set time explicitly
  const end = new Date(`${endDate}T23:59:59`); // Ensure the entire day is included

  // Check if current date is within the range (inclusive)
  return currentDate >= start && currentDate <= end;
}

export const activeBogoLists = (bogoList = []) => {
  if (bogoList.length <= 0) return [];

  const lists = bogoList.filter((list) => {
    if (list.is_disable === '1') return false; // deal should not be Disabled
    if (list.no_end_date === '1') return true; // if deal is never ending, accept it directly

    const isActiveBogoDeal = isDealActive(list.start_date, list.end_date); // check if deal is expired or not
    if (isActiveBogoDeal) return true; // if deal is not expired then only take it

    return false;
  });

  return lists && lists.length > 0 ? lists : [];
};

export const handleBogoDeals = (cart = [], bogoList = []) => {
  // time stamp counter for every line item - unique identifier
  let timeStampCounter = Date.now();

  // making single qty line items
  let singleLineItems = [];
  cart.forEach((item) => {
    if (item.selectedQuantity > 1) {
      for (let i = 0; i < item.selectedQuantity; i++) {
        singleLineItems.push({
          ...item,
          selectedQuantity: 1,
          price: item.price / item.selectedQuantity,
          timeStamp: timeStampCounter++,
          allowCoupon: true,
        });
      }
    } else {
      singleLineItems.push({
        ...item,
        timeStamp: timeStampCounter++,
        allowCoupon: true,
      });
    }
  });

  // filtering only valid bogo lists
  const validBogoList = activeBogoLists(bogoList);

  let cartAfterBogo = [];

  // looping on valid bogo lists
  validBogoList.forEach((list) => {
    // filtering only this bogo deal products from cart
    const eligibleProducts = singleLineItems.filter((item) => {
      const itemId =
        item.varients.length > 0 ? item.variantId : item.product.id;
      return list.items.includes(itemId);
    });

    if (eligibleProducts.length > 0) {
      // adding each deal product in bogo cart with coupon key allowCoupon
      // eligibleProducts.forEach((item) => cartAfterBogo.push(item));

      // total qty of this bogo deal products
      const totalQty = eligibleProducts.reduce(
        (acc, curr) => (acc += curr.selectedQuantity),
        0,
      );

      const totalSets = Math.floor(totalQty / +list.buy_qty);

      // checking if Total qty of this deal products is more than or equal to the deal buy qty
      // if it is more or equal then only apply the deal prices

      // +totalQty >= +list.buy_qty
      if (totalSets > 0) {
        // allow coupon key

        // sorting based on the addedOn key such that we can form sets line by line
        const sortedByAddedOn = eligibleProducts.toSorted(
          (a, b) => a.addedOn - b.addedOn,
        );
        // console.log('sortedByAddedOn: ', sortedByAddedOn);

        // forming groups of sets
        const groups = [];
        let copyArray = [...sortedByAddedOn];
        const buyQty = +list.buy_qty;
        for (let i = 0; i < totalSets; i++) {
          const singleGroup = copyArray.slice(0, buyQty);
          const sortedGroup = singleGroup.toSorted((a, b) => b.price - a.price);
          groups.push(sortedGroup);
          copyArray = copyArray.slice(buyQty);
        }
        // if (copyArray.length > 0) {
        //   groups.push(copyArray);
        // }

        // sorting products in descending price order
        // const sortedItems = eligibleProducts.toSorted(
        //   (a, b) => b.price - a.price,
        // );

        // now create bogo sets and reduce the price thats it...
        const freeQty = +list.free_qty * totalSets; // deal free qty
        const discount = parseFloat(list.discount) || 0; // deal discount amount
        const discountType = parseFloat(list.discount_type) || 2; // deal discount type - 1 = percentage, 2 = flat amount

        // decrease prices from last products based on free qty..
        for (let i = 0; i < freeQty; i++) {
          // const itemToUpdate = sortedItems[sortedItems.length - i];

          const group = groups[i];
          const itemToUpdate = group[group.length - 1];

          const itemPrice = itemToUpdate.price;
          const newPrice =
            discountType === 2
              ? itemPrice - discount
              : itemPrice - parseFloat((itemPrice * discount) / 100).toFixed(2);
          const newItem = {
            ...itemToUpdate,
            price: newPrice,
            bogoDiscountApplied: true,
            bogoDiscount: itemPrice - newPrice,
            // bogoApplied: true,
            // allowCoupon: list.use_with_coupon === '1',
          };

          const productExists = cartAfterBogo.find(
            (item) => item.timeStamp === newItem.timeStamp,
          );

          if (productExists) {
            cartAfterBogo = cartAfterBogo.map((item) =>
              item.timeStamp === newItem.timeStamp ? newItem : item,
            );
          } else {
            cartAfterBogo.push(newItem);
          }
        }
      }
    }
  });

  if (!(cartAfterBogo.length > 0)) {
    return singleLineItems;
  }

  const finalCart = singleLineItems.map((item) => {
    const takeFromBogoCart = cartAfterBogo.find((bogoItem) => {
      return bogoItem.timeStamp === item.timeStamp;
    });

    return takeFromBogoCart ? takeFromBogoCart : item;
  });

  return finalCart;
};

// updating local storage cart
export function updateCart(cartKey, productId, type, cartProductId) {
  // for local storage cart
  let cartData = JSON.parse(localStorage.getItem(cartKey)) || [];

  // const newItem = cartData.find((item) => item.productId === productId);
  const newItem = cartData.find((item) => item.cartProductId === cartProductId);
  if (!newItem) return;

  if (type === 'increment') {
    // for increment simply add a new item in the bogo cart to make it 1 - 1 line items
    const addedOn =
      cartData.length > 0 ? cartData[cartData.length - 1].addedOn + 1 : 1;
    // console.log('new addedOn: ', addedOn);
    cartData.push({
      ...newItem,
      addedOn,
    });
    localStorage.setItem(cartKey, JSON.stringify(cartData));
  } else {
    // for decrement - delete the last one added from this same Product id
    const matchingProducts = cartData.filter(
      (item) => item.cartProductId === cartProductId,
    );
    const lastProduct = matchingProducts[matchingProducts.length - 1];
    const updatedCartData = cartData.filter(
      (item) => item.addedOn !== lastProduct.addedOn,
    );

    localStorage.setItem(cartKey, JSON.stringify(updatedCartData));
  }
}
